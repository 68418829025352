<template>
  <div class="modal-backdrop">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Напишите отзыв о покупке</h5>
        <button type="button" class="btn-close" @click="closeModal">x</button>
      </div>
      <div class="modal-body">
        <div class="rating mb-2">
          <span
            class="stars me-2"
            :class="{ select: selectRating >= 1 }"
            @click="selectRating = 1"
            >★</span
          >
          <span
            class="stars me-2"
            :class="{ select: selectRating >= 2 }"
            @click="selectRating = 2"
            >★</span
          ><span
            class="stars me-2"
            :class="{ select: selectRating >= 3 }"
            @click="selectRating = 3"
            >★</span
          ><span
            class="stars me-2"
            :class="{ select: selectRating >= 4 }"
            @click="selectRating = 4"
            >★</span
          ><span
            class="stars me-2"
            :class="{ select: selectRating >= 5 }"
            @click="selectRating = 5"
            >★</span
          >
        </div>
        <textarea
          class="form-control"
          rows="5"
          v-model="comment"
          placeholder="Комментарий к отзыву"
          required
        ></textarea>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="sendComment"
          :disabled="!comment || comment === null"
        >
          Отправить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import MarketplaceService from "@/services/marketplace";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "ReviewModal",
  emits: ["close"],
  props: {
    id: String,
  },
  setup(props, { emit }) {
    console.log(props);
    const selectRating = ref(0);
    const parentComment = ref(null);
    const comment = ref(null);
    const closeModal = async () => {
      emit("close");
    };

    const sendComment = () => {
      if (selectRating.value !== 0 && comment.value !== "") {
        MarketplaceService.sendComment(
          selectRating.value,
          comment.value,
          props.id,
          parentComment.value
        );
        closeModal();
      }
    };
    return {
      closeModal,
      sendComment,
      comment,
      selectRating,
    };
  },
});
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-close {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
}

textarea.form-control {
  width: 100%;
  margin-bottom: 20px;
}
</style>
