<template>
  <div class="profile pb-4">
    <div class="card profile-info">
      <div class="card-body">
        <h2>Подписка</h2>
        <p>
          Доступ действует до<span class="bold">26.07.2024.</span
          ><a href="#">Продлить</a>
        </p>
        <p>
          В подписке<span class="bold">В подписке</span
          ><a href="#">Подключить еще</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Subscription",
});
</script>
