<template>
  <div class="card main-info">
    <div class="card-body">
      <h5 class="card-title">Основная информация</h5>
      <ul class="info-list">
        <li class="pt-3">
          <span class="info-title">Основной вид деятельности:</span>
          <span class="info-value">{{ company.okved?.name }}</span>
        </li>
        <div class="d-flex pt-3">
          <li class="col-4">
            <span class="info-title">ИНН:</span>
            <span class="info-value">{{ company.inn }}</span>
          </li>
          <li class="col-4">
            <span class="info-title">КПП:</span>
            <span class="info-value">{{ company.kpp }}</span>
          </li>
          <li class="col-4">
            <span class="info-title">ОГРН:</span>
            <span class="info-value">{{ company.ogrn }}</span>
          </li>
        </div>
        <div class="d-flex pt-3">
          <li class="col-6">
            <span class="info-title">Дата регистрации:</span>
            <span class="info-value">{{ company.registration_date }}</span>
          </li>
          <li class="col-6" v-id="company.share_capital">
            <span class="info-title">Уставный капитал:</span>
            <span class="info-value">{{ company.share_capital?.total }}</span>
          </li>
        </div>
        <li class="pt-3">
          <span class="info-title">Юридический адрес:</span>
          <span class="info-value">{{ company.legal_adress.city }} </span>
        </li>
        <li class="pt-3">
          <span class="info-title">Руководитель:</span>
          <span class="info-value">{{
            company.directors.length ? company.directors[0].fio : "Неизвестно"
          }}</span>
        </li>
        <li class="pt-3">
          <span class="info-title">Держатель реестров акционеров:</span>
          <span class="info-value">Акционерное общество АО</span>
        </li>
        <li class="pt-3">
          <span class="info-title">Среднесписочная численность:</span>
          <span class="info-value">нет данных</span>
        </li>
        <li class="pt-3">
          <span class="info-title">Специальный налоговый режим:</span>
          <span class="info-value">Не применяется</span>
        </li>
        <div class="d-flex pt-3">
          <li class="col-6">
            <span class="info-title">Реестр МСП:</span>
            <span class="info-value">Не применяется</span>
          </li>
          <li class="col-6">
            <span class="info-title">Место в отрасли:</span>
            <span class="info-value">Не применяется по выручке</span>
          </li>
        </div>
        <li class="pt-3">
          <span class="info-title">Налоговый орган:</span>
          <span class="info-value">{{ company.taxAuthority?.name }} </span>
        </li>
        <li class="py-3">
          <span class="info-title">Коды статистики:</span>
          <span class="info-value"
            >ОКПО {{ company.okpo?.code }} <br />ОКАТО
            {{ company.okato?.code }} <br />ОКТМО {{ company.oktmo?.code }}
            <br />ОКФС {{ company.okfs?.code }} <br />{{ company.okfs?.name }}
            <br />ОКОГУ {{ company.okogu?.code }} <br />{{
              company.okogu?.name
            }}
            <br />ОКОПФ
            {{ company.okopf?.code }}
            {{ company.okopf?.code }}
          </span>
        </li>
        <div class="divider"></div>
        <li class="py-3">
          <span class="info-value"
            >По организации доступны <a href="#">исторические сведения 777</a
            ><br /><a href="#">Это ваша компания ?</a></span
          >
        </li>
        <div class="divider"></div>

        <li class="pt-3">
          <span class="info-value">Актуально на 21.08.2024</span>
        </li>
        <li class="pt-3">
          <span class="info-value"
            >Председатель правления ПАО "Тундра" - Прохор Алексей Борисович (ИНН
            782506399397). Организации присвоен ИНН 7736059903, ОГРН
            102999070518</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import company from "@/services/company";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "MainInfo",
  props: {
    company: Object,
  },
  setup(props) {
    const store = useStore();
    return {
      isLoading: store.getters.isLoading,
      error: store.getters.getError,
    };
  },
});
</script>
