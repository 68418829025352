<template>
  <div class="sidebar-profile">
    <div class="user-info">
      <div class="user-avatar">
        <img
          :src="newAvatar ? newAvatar : currentUser.avatar"
          alt="User Avatar"
        />
      </div>
      <div class="user-details">
        <p class="account-label">Аккаунт</p>
        <a :href="currentUser.email" class="user-email">{{
          currentUser.email
        }}</a>
      </div>
    </div>
    <div class="divider"></div>
    <nav class="menu">
      <ul>
        <li v-for="(item, index) in sidebarList" :key="index">
          <router-link
            :to="item.link"
            class="menu-link px-4"
            active-class="active"
          >
            <img :src="item.icon" class="me-4" /> {{ item.title }}
          </router-link>
        </li>
        <li>
          <a class="menu-link px-4" @click="logout()" active-class="active">
            <img src="@/assets/images/icons/logout.svg" class="me-4" /> Выйти
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import UserService from "@/services/user";
import { useRouter } from "vue-router";
import { sidebarList } from "@/models/sidebar";

export default defineComponent({
  name: "ProfileSidebar",
  setup() {
    const fileInput = ref(null);
    const newAvatar = ref(null);
    const router = useRouter();
    const store = useStore();
    const currentUser = computed(() => store.state.user.user);

    const triggerFileUpload = () => {
      fileInput.value.click();
    };

    const uploadImage = async (event) => {
      const file = event.target.files[0];
      if (file) {
        newAvatar.value = URL.createObjectURL(file);
        const avatar = new FormData();
        avatar.append("avatar", file);
        await UserService.updateAvatar(avatar);
      }
    };

    const logout = async () => {
      await store.dispatch("auth/logout");
      router.push("/");
    };

    return {
      fileInput,
      currentUser,
      newAvatar,
      sidebarList,
      triggerFileUpload,
      uploadImage,
      logout,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/profile/sidebar.scss";
</style>
