<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card finances">
      <div class="card-header">
        <h5 class="card-title mb-0">Финансы</h5>
      </div>

      <div class="d-flex navs">
        <ul class="nav nav-tabs">
          <li>
            <a
              class="active"
              id="analyzesmall-tab"
              data-bs-toggle="tab"
              href="#tab-analyzesmall"
              role="tab"
              aria-controls="tab-analyzesmall"
              aria-selected="true"
              >Анализ</a
            >
          </li>
          <li class="ms-4">
            <a
              id="report-tab"
              data-bs-toggle="tab"
              href="#tab-report"
              role="tab"
              aria-controls="tab-report"
              aria-selected="false"
              >Бухгалтерская отчетность</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <!-- Первый таб -->
          <div
            class="tab-pane fade show active"
            id="tab-analyzesmall"
            role="tabpanel"
            aria-labelledby="analyzesmall-tab"
          >
            <ul
              class="info-list d-flex flex-md-row flex-column col-md-9 col-12"
            >
              <li class="d-flex flex-row col-md-3 col-12 align-items-center">
                <span class="info-title">Все факторы</span>
                <span class="info-value">34</span>
              </li>
              <li class="d-flex flex-row col-md-3 col-12 align-items-center ms">
                <span class="info-value d-flex align-items-center">
                  <div class="circle success me-3"></div>
                  Положительные</span
                >
                <span class="text-end">5</span>
              </li>
              <li
                class="d-flex flex-row col-md-3 col-12 align-items-center ms-md-4"
              >
                <span class="info-value d-flex align-items-center">
                  <div class="circle danger me-3"></div>
                  Требуют внимания</span
                >
                <span class="text-end">5</span>
              </li>
              <li
                class="d-flex flex-row col-md-3 col-12 align-items-center ms-md-4"
              >
                <span class="info-value d-flex align-items-center">
                  <div class="circle decline me-3"></div>
                  Отрицательные</span
                >
                <span class="text-end">5</span>
              </li>
            </ul>
            <div class="row mt-4">
              <div class="col-md-5">
                <ul class="info-list">
                  <li
                    class="d-flex flex-row border-bottom align-items-center pt-4"
                  >
                    <span class="info-value">Выручка</span>
                    <span class="info-bold">456709</span>
                  </li>
                  <li
                    class="d-flex flex-row border-bottom align-items-center pt-2"
                  >
                    <span class="info-value">Прибыль</span>
                    <span class="info-bold">456709</span>
                  </li>
                  <li
                    class="d-flex flex-row border-bottom align-items-center pt-2"
                  >
                    <span class="info-value">Стоимость</span>
                    <span class="info-bold">456709</span>
                  </li>
                  <li
                    class="d-flex flex-row border-bottom align-items-center pt-2"
                  >
                    <span class="info-value">Дебиторская задолженность</span>
                    <span class="info-bold">456709</span>
                  </li>
                  <li
                    class="d-flex flex-row border-bottom align-items-center pt-2"
                  >
                    <span class="info-value">Кредиторская задолженность</span>
                    <span class="info-bold">456709</span>
                  </li>
                </ul>
              </div>
              <div class="col-md-5">
                <BarChart :chartData="chartData" :chartOptions="chartOptions" />
              </div>
            </div>
            <div class="accordion mt-4" id="accordionOne">
              <div
                class="accordion-item px-2"
                v-for="(factor, index) in finances_factors"
                :key="index"
                :id="'heading' + index"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    @click="toggleCollapse(index)"
                    class="accordion-button collapsed"
                    type="button"
                    :data-bs-toggle="'collapse'"
                    :data-bs-target="'#collapse' + index"
                    :aria-expanded="isOpen === index ? 'true' : 'false'"
                    :aria-controls="'collapse' + index"
                  >
                    <div class="circle me-3" :class="factor.status"></div>
                    {{ factor.title }} :
                  </button>
                </h2>
                <div
                  :id="'collapse' + index"
                  class="accordion-collapse collapse"
                  :class="{ show: isOpen === index }"
                  :aria-labelledby="'heading' + index"
                  data-bs-parent="#headingOne"
                >
                  <div class="accordion-body">
                    {{ factor.text }}
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion mt-4" id="accordionTwo">
              <div
                class="accordion-item px-2"
                v-for="(factor, index) in payment_factors"
                :key="index"
                :id="'heading-two' + index"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    @click="toggleCollapse(index)"
                    class="accordion-button collapsed"
                    type="button"
                    :data-bs-toggle="'collapse-two'"
                    :data-bs-target="'#collapse-two' + index"
                    :aria-expanded="isOpen === index ? 'true' : 'false'"
                    :aria-controls="'collapse-two' + index"
                  >
                    <div class="circle me-3" :class="factor.status"></div>
                    {{ factor.title }} :
                  </button>
                </h2>
                <div
                  :id="'collapse-two' + index"
                  class="accordion-collapse collapse"
                  :class="{ show: isOpen === index }"
                  :aria-labelledby="'heading-two' + index"
                  data-bs-parent="#accordionTwo"
                >
                  <div class="accordion-body">
                    {{ factor.text }}
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion mt-4" id="accordionThree">
              <div
                class="accordion-item px-2"
                v-for="(factor, index) in effectivity_factors"
                :key="index"
                :id="'heading-three' + index"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    @click="toggleCollapse(index)"
                    class="accordion-button collapsed"
                    type="button"
                    :data-bs-toggle="'collapse-three'"
                    :data-bs-target="'#collapse-three' + index"
                    :aria-expanded="isOpen === index ? 'true' : 'false'"
                    :aria-controls="'collapse-three' + index"
                  >
                    <div class="circle me-3" :class="factor.status"></div>
                    {{ factor.title }} :
                  </button>
                </h2>
                <div
                  :id="'collapse-three' + index"
                  class="accordion-collapse collapse"
                  :class="{ show: isOpen === index }"
                  :aria-labelledby="'heading-three' + index"
                  data-bs-parent="#accordionThree"
                >
                  <div class="accordion-body">
                    {{ factor.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Второй таб -->
          <div
            class="tab-pane fade"
            id="tab-report"
            role="tabpanel"
            aria-labelledby="report-tab"
          >
            <!-- Фильтр и кнопка "Скачать" -->
            <div
              class="card d-flex flex-row justify-content-between align-items-center col-10 px-4 py-4 mt-4"
            >
              <p class="mb-0">
                Бухгалтерская отчетность за период с 21.03.1999 по 15.01.2005
              </p>
              <button class="btn-primary btn-small">Скачать</button>
            </div>

            <!-- Бухгалтерский баланс -->
            <h5 class="finances-title mt-4 pt-4 pb-2">Бухгалтерский баланс</h5>
            <div class="card mt-4 inner-card">
              <div class="card-body">
                <!-- Примерный график на Chart.js -->
                <div class="row">
                  <div class="col-6">
                    <!-- <canvas id="balanceChart"></canvas> -->
                    <BarChart />
                  </div>
                </div>

                <!-- Таблица данных -->
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" class="text-center">Код</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="font-weight-bold" colspan="8">БАЛАНС АКТИВ</th>
                    </tr>
                    <tr>
                      <th scope="row">Внеоборотные активы</th>
                      <td class="text-center">95883</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Оборотные активы</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th class="font-weight-bold" colspan="8">
                        БАЛАНС ПАССИВ
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">Капитал и резервы</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Долгосрочные обязательства</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Краткосрочные обязательства</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Другие секции, аналогичные выше -->
            <h5 class="finances-title mt-4 pt-4 pb-2">
              Отчет о финансовых результатах
            </h5>

            <div class="card inner-card mt-4">
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" class="text-center">Код</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                      <th scope="col" class="text-center">год</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Выручка</th>
                      <td class="text-center">95883</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Себестоимость продаж</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Валовая прибыль (убыток)</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Прибыль (убыток) от продаж</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Прибыль (убыток) до налогообложения</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                    <tr>
                      <th scope="row">Чистая прибыль (убыток)</th>
                      <td class="text-center"></td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                      <td class="text-center">...</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import BarChart from "./cards/Diagrams/BarChart.vue";

export default defineComponent({
  name: "Finances",
  props: {
    finances: Object,
  },
  components: {
    BarChart,
  },
  setup() {
    const isOpen = ref(null);

    const finances_factors = ref([
      {
        title: "Финансовая устойчивость",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Зависимость от кредитов",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Обеспеченность собственными средствами",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Зависимость от дебиторов",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Доля заемного капитала",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const payment_factors = ref([
      {
        title: "Абсолютная ликвидность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Текущая ликвидность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Обеспеченность активами",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Платежеспособность по текущим обязательствам",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Вероятность утраты платежеспособности",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const effectivity_factors = ref([
      {
        title: "Вероятность утраты платежеспособности",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Эффективность вложений",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Отдача от использования активов",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Рентабельность собственного капитала",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Оборачиваемость дебиторской задолженности :",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Оборачиваемость кредиторской задолженности",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);
    // Данные для графика
    const chartData = {
      labels: [
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      datasets: [
        {
          label: "Region A",
          data: [10, 20, 15, 25, 30, 35, 40, 35, 20, 15, 38, 22, 35],
          backgroundColor: "#86C2FF4D",
          borderColor: "#86C2FF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
        {
          label: "Region B",
          data: [12, 18, 20, 22, 28, 32, 38, 10, 20, 15, 25, 32, 35],
          backgroundColor: "#B17BFF4D",
          borderColor: "#B17BFF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
      ],
    };

    // Опции для графика
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.parsed.y;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Sales",
          },
        },
        y: {
          display: true, // Скрывает ось X
          grid: {
            display: true, // Скрывает сетку по оси X
          },
          title: {
            display: false, // Скрывает заголовок оси X
          },
          ticks: {
            display: false, // Скрывает заголовок оси X
          },
        },
        r: {
          ticks: {
            callback: function (value) {
              return value.toLocaleString("en-US", {
                minimumFractionDigits: Math.min(Math.max(0, 100), 20),
              });
            },
          },
        },
      },
    };
    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    return {
      isOpen,
      finances_factors,
      payment_factors,
      effectivity_factors,
      toggleCollapse,
      chartData,
      chartOptions,
    };
  },
});
</script>
