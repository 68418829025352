<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Контактная информация</h5>
      <ul class="info-list">
        <li class="pt-3">
          <span class="info-title">Телефон :</span>
          <span
            class="info-value border-bottom pt-2"
            v-for="(phone, index) in contacts?.phone"
            :key="index"
            >{{ phone }}</span
          >
        </li>
        <li class="pt-3">
          <span class="info-title">Электронная почта:</span>
          <span
            class="info-value border-bottom pt-2"
            v-for="(email, index) in contacts?.email"
            :key="index"
            >{{ email }}</span
          >
        </li>
        <li class="pt-3">
          <span class="info-title">Сайт:</span>
          <span class="info-value border-bottom pt-1">{{
            contacts?.website
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "ContactInfo",
  props: {
    contacts: Object,
  },
  setup() {
    const store = useStore();
    return {
      isLoading: store.getters.isLoading,
      error: store.getters.getError,
    };
  },
});
</script>
