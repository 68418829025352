<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Проверки</h5>

      <ul class="info-list mt-2">
        <li
          class="d-flex flex-row border-bottom align-items-end pt-2"
          v-for="(count, type) in inspectionTypeCounts"
          :key="type"
          :value="type"
        >
          <span class="info-value">{{ type }}</span
          ><span class="info-bold text-end">{{ count }}</span>
        </li>
        <li
          class="d-flex flex-row border-bottom align-items-end pt-2"
          v-for="(count, type) in inspectionStatusCounts"
          :key="type"
          :value="type"
        >
          <span class="info-value">{{ type }}</span
          ><span class="info-bold text-end">{{ count }}</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-inspections')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "CheckingsInfo",
  props: {
    inspections: Array,
  },
  setup(props, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    const inspectionTypeCounts = computed(() => {
      if (props.inspections.length) {
        return props.inspections.reduce((acc, inspection) => {
          acc[inspection.verification_type] =
            (acc[inspection.verification_type] || 0) + 1;
          return acc;
        }, {});
      } else {
        return [];
      }
    });

    const inspectionStatusCounts = computed(() => {
      if (props.inspections.length) {
        return props.inspections?.reduce((acc, inspection) => {
          acc[inspection.status] = (acc[inspection.status] || 0) + 1;
          return acc;
        }, {});
      } else {
        return [];
      }
    });

    return {
      inspectionTypeCounts,
      inspectionStatusCounts,
      emitChangeTab,
    };
  },
});
</script>
