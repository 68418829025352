<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Реквизиты</h5>
      </div>
      <div class="card-body">
        <div
          class="connections-list"
          v-for="(org, index) in organizations"
          :key="index"
        >
          <div class="connections-list__header">
            <h5 class="blue">{{ org.title }}</h5>
          </div>
          <ul
            class="info-list d-flex justify-content-between mt-3 flex-md-row flex-column"
          >
            <li
              v-for="(item, index) in org.lists"
              :key="index"
              style="min-width: 20%"
            >
              <span class="info-title">{{ item.label }}</span>
              <span class="info-value">{{ item.value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Details",
  props: {
    company: Object,
  },
  setup(props) {
    const selectedType = ref("Все");
    const organizations = ref([
      {
        title: "Основная информация",
        lists: [
          { label: "ИНН", value: props.company.inn },
          { label: "КПП", value: props.company.kpp },
          { label: "ОГРН", value: props.company.ogrn },
          {
            label: "Дата постановки на учет",
            value: props.company.registration_date,
          },
          {
            label: "Налоговый орган",
            value: props.company.taxAuthority?.name,
          },
        ],
      },
      {
        title: "Сведения Росстата",
        lists: [
          { label: "ОКПО", value: props.company.okpo },
          { label: "ОКАТО", value: props.company.okato?.code },
          { label: "ОКФС", value: props.company.okfs?.code },
          {
            label: "ОКТМО",
            value: props.company.oktmo?.code + ", " + props.company.oktmo?.name,
          },
          {
            label: "ОКОГУ",
            value: props.company.okogu?.code + ", " + props.company.okogu?.name,
          },
        ],
      },
      {
        title: "Сведения о регистрации в ФНС",
        lists: [
          { label: "ОГРН", value: props.company.ogrn },
          {
            label: "Дата присвоения ОГРН",
            value: props.company.ogrn_start_date,
          },
          {
            label: "Регистратор",
            value: props.company.taxAuthority?.name,
          },
          {
            label: "Адрес регистратора",
            value: props.company.taxAuthority.address,
          },
        ],
      },
      {
        title: "Сведения о регистрации в ПФР ",
        lists: [
          {
            label: "Регистрационный номер",
            value: props.company.pensionFund?.registration_number,
          },
          {
            label: "Дата регистрации",
            value: props.company.pensionFund?.registration_date,
          },
          {
            label: "Наименование территориального отдыха",
            value: props.company.pensionFund?.name,
          },
        ],
      },
      {
        title: "Сведения о регистрации в ФСС ",
        lists: [
          {
            label: "Регистрационный номер",
            value: props.company.socialInsuranceFund?.registration_number,
          },
          {
            label: "Дата регистрации",
            value: props.company.socialInsuranceFund?.registration_date,
          },
          {
            label: "Наименование территориального отдыха",
            value: props.company.socialInsuranceFund?.name,
          },
        ],
      },
      {
        title: "Сведения МСП ",
        lists: [
          {
            label: "Дата включения",
            value: props.company.socialInsuranceFund?.registration_number,
          },
          {
            label: "Категория субъекта",
            value: props.company.socialInsuranceFund?.registration_date,
          },
        ],
      },
    ]);

    return {
      selectedType,
      organizations,
    };
  },
});
</script>
