<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title mb-0">Анализ надежности</h5>
        <span class="status ms-4" :class="getClass(company?.status?.name)">{{
          company?.status?.name
        }}</span>
      </div>

      <div class="px-4">
        <Gauge />
      </div>
      <ul class="info-list">
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom"
        >
          <span class="info-value success">Положительные</span
          ><span class="info-bold">15</span>
        </li>
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value danger">Требуют внимания</span
          ><span class="info-bold">2</span>
        </li>
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value decline">Отрицательные</span>
          <span class="info-bold">0</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-reliability')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Gauge from "./Diagrams/Gauge.vue";

export default defineComponent({
  name: "AnalyzeInfo",
  props: {
    activeTab: String,
    company: Object,
  },
  components: { Gauge },
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    const getClass = (status) => {
      if (status === "Действует") {
        return "active";
      } else if (status === "Не действует") {
        return "inactive";
      } else {
        return "liquidated";
      }
    };

    return {
      emitChangeTab,
      getClass,
    };
  },
});
</script>
