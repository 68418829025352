<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Конкуренты</h5>
      <span
        >Список конкурентов составляется в результате анализа участия компании в
        тендерах и гос. закупках.</span
      >
      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Выручка </span
          ><span class="col-8 info-bold text-end">15 000 000</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Госконтракты </span
          ><span class="col-8 info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">На сумму </span
          ><span class="col-8 info-bold text-end">390 млр. р.</span>
        </li>
      </ul>
      <button class="btn-primary btn-small d-block ms-auto mt-2">
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoundersInfo",
};
</script>
