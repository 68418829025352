import api from "./api";
import TokenService from "./token";
import router from "../router";

// Определяем типы для данных авторизации и регистрации
interface LoginData {
  email: string;
  password: string;
}

interface RegisterData {
  full_name: string;
  email: string;
  password: string;
}

interface RegistrationResponse {
  data: any;
}

interface ErrorResponse {
  response: {
    data: {
      email: string[];
    };
  };
}

class AuthService {
  // Авторизация
  async login({ email, password }: LoginData): Promise<any> {
    try {
      const res = await api.post("/auth/jwt/create", { email, password });
      if (res.data.access) {
        TokenService.setUser(res.data);
      }
      return res.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Login error:", error);
      throw error;
    }
  }

  // Выход
  logout(): void {
    TokenService.removeUser();
    router.push("/");
  }

  // Регистрация
  async register({ full_name, email, password }: any): Promise<any> {
    try {
      const response = await api.post("/auth/users", {
        full_name,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Registration error:", error);
      throw error;
    }
  }

  async setPassword({ new_password, current_password }: any): Promise<any> {
    try {
      const response = await api.post("/auth/users/set_password", {
        new_password,
        current_password,
      });

      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Password set error:", error);
      throw error;
    }
  }

  // Регистрация с дополнительной обработкой ошибок
  async registration(data: RegisterData): Promise<RegistrationResponse> {
    try {
      const response = await api.post("/auth/users/", data);
      return response;
    } catch (error) {
      const err = error as ErrorResponse;
      if (
        err.response.data.email[0] ===
        "Пользователь with this Электронная почта already exists."
      ) {
        return Promise.reject(error);
      }
      throw error;
    }
  }
}

export default new AuthService();
