<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="row justify-content-between">
      <div class="col-md-3 col-12">
        <ul class="info-list">
          <li>
            <span class="info-title" style="font-size: 20px">Ответчик</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">Рассматривается</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-4">
            <span class="info-value">Завершено</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-12">
        <ul class="info-list">
          <li>
            <span class="info-title" style="font-size: 20px">Истец</span>
          </li>

          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">Рассматривается</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-4">
            <span class="info-value">Завершено</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-12">
        <ul class="info-list">
          <li>
            <span class="info-title" style="font-size: 20px">Иное лицо</span>
          </li>

          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">Рассматривается</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-4">
            <span class="info-value">Завершено</span
            ><span class="info-bold">5463</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">На сумму</span
            ><span class="info-bold">12450</span>
          </li>
        </ul>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 me-4">
          <PolarChart :chartData="data" />
        </div>
        <div class="col-md-3 col-12 ms-4">
          <!-- <PolarChart :chartData="data" /> -->
        </div>
      </div>
      <div class="row">
        <h5 class="finances-title mt-4 pt-4 pb-2">Категория дел</h5>

        <div class="card mt-4 inner-card">
          <div class="card-body">
            <!-- Таблица данных -->
            <table class="table table-bordered colored">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" class="text-center">Ответчик</th>
                  <th scope="col" class="text-center">Истец</th>
                  <th scope="col" class="text-center">Иное лицо</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Энергоснабжение</th>
                  <td class="text-center">95883</td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры возмездного оказания услуг</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры поставки</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры подряда</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры аренды</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры перевозки</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Банкротство</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Банковская гарантия</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>

                <tr>
                  <th scope="row">Защита исключительных прав</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Договоры страхования</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Корпоративные споры</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
                <tr>
                  <th scope="row">Строительство</th>
                  <td class="text-center"></td>
                  <td class="text-center">...</td>
                  <td class="text-center">...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import PolarChart from "./cards/Diagrams/PolarChart.vue";

export default defineComponent({
  name: "ArbitrationStatistics",
  components: { PolarChart },
  setup() {
    const selectedType = ref("Все");
    const organizations = ref([
      {
        name: 'АО "Тандер"',
        status: "Ликвидирована",
        statusClass: "active",
        director: "Лобанова Елизавета Сергеевна",
        address:
          "142520, Московская область, г. Павловский Посад, ул. Белозерская, д. 11А",
        inn: "4547487477",
        ogrn: "4547487477",
        registrationDate: "15.01.1999",
        capital: "100 000 000 р.",
        activity:
          "47.11 Торговля розничная преимущественно пищевыми продуктами",
      },
      {
        name: 'АО "Тандер"',
        status: "Действующая",
        statusClass: "active",
        director: "Лобанова Елизавета Сергеевна",
        address:
          "142520, Московская область, г. Павловский Посад, ул. Белозерская, д. 11А",
        inn: "4547487477",
        ogrn: "4547487477",
        registrationDate: "15.01.1999",
        capital: "100 000 000 р.",
        activity:
          "47.11 Торговля розничная преимущественно пищевыми продуктами",
      },
    ]);

    // Пример данных для полярного графика
    const data = {
      labels: [
        "Не проиграно",
        "Частично проиграно",
        "Проиграно",
        "Рассматривается",
        "Определить не удалось",
        "Прекращено",
      ],
      datasets: [
        {
          label: "My Polar Chart",
          data: [11, 16, 7, 22, 16, 12],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const filteredOrganizations = computed(() => {
      if (selectedType.value === "Все") {
        return organizations.value;
      }
      return organizations.value.filter(
        (org) => org.status === selectedType.value
      );
    });
    return {
      selectedType,
      filteredOrganizations,
      //   options,
      data,
    };
  },
});
</script>
