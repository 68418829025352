
import { information } from "@/models/main-page";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InformationSection",
  setup() {
    return {
      information,
    };
  },
});
