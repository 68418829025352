<template>
  <div class="col-12 connections py-4 mb-4" v-if="isLoggedIn">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Проверки</h5>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between flex-md-row flex-column">
          <div
            class="filter-container d-flex flex-md-row flex-column align-items-center my-4"
          >
            <label for="role-filter">Период</label>
            <input
              type="date"
              id="start-date"
              class="form-control ms-4"
              v-model="startDate"
            />
            <input
              type="date"
              id="end-date"
              class="form-control ms-4"
              v-model="endDate"
            />
          </div>

          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Тип</label>
            <select
              id="type-filter"
              class="form-select ms-4"
              v-model="selectedType"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Объявление предостережения">
                Объявление предостережения
              </option>
              <option value="Профилактический визит">
                Профилактический визит
              </option>
              <option value="Документарная">Документарная</option>
            </select>
          </div>

          <div class="filter-container d-flex align-items-center my-4">
            <label for="status-filter">Статус</label>
            <select
              id="status-filter"
              class="form-select ms-4"
              v-model="selectedStatus"
            >
              <option value="Все">Все</option>
              <option value="Завершено">Завершено</option>
              <option value="Предостережение объявлено">
                Предостережение объявлено
              </option>
            </select>
          </div>
        </div>

        <div
          class="connections-list"
          v-for="inspection in filteredInspections"
          :key="inspection.id"
        >
          <div class="connections-list__header">
            <p>№ {{ inspection.number }} от {{ inspection.start_date }}</p>
          </div>
          <div class="connections-list__body col-10">
            <p class="value">
              <span class="label">Период проведения</span> с
              {{ inspection.start_date }} по {{ inspection.end_date }}
            </p>
            <p class="value">
              <span class="label">Статус </span>{{ inspection.status }}
            </p>
            <p class="value">
              <span class="label">Тип </span>{{ inspection.verification_type }}
            </p>
            <p class="value">
              <span class="label">Адрес </span
              >{{ inspection.inspection_objects[0].address }}
            </p>
            <p class="value">
              <span class="label">Орган контроля </span
              >{{ inspection.control_body?.name }}
            </p>
            <p class="value">
              <span class="label">Результат </span
              >{{ inspection.basis_conducting }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Inspections",
  props: {
    inspections: Array,
  },
  setup(props) {
    const store = useStore();

    const selectedRole = ref("Все");
    const selectedType = ref("Все");
    const selectedStatus = ref("Все");
    const startDate = ref("");
    const endDate = ref("");
    const isLoggedIn = store.state.auth.status.loggedIn;

    const filteredInspections = computed(() => {
      if (props.inspections.length) {
        return props.inspections.filter((inspection) => {
          const startMatch =
            !startDate.value ||
            new Date(inspection.start_date) >= new Date(startDate.value);
          const endMatch =
            !endDate.value ||
            new Date(inspection.end_date) <= new Date(endDate.value);
          const typeMatch =
            selectedType.value === "Все" ||
            inspection.verification_type === selectedType.value;
          const statusMatch =
            selectedStatus.value === "Все" ||
            inspection.status === selectedStatus.value;

          return startMatch && endMatch && typeMatch && statusMatch;
        });
      } else {
        return [];
      }
    });

    return {
      isLoggedIn,
      startDate,
      endDate,
      selectedRole,
      selectedType,
      selectedStatus,
      filteredInspections,
    };
  },
});
</script>
