import api from "./api";

interface Support {
  text: string;
}

class SupportService {
  async createSupport(support: Support): Promise<any> {
    const res = await api.post("/api/v1/support/", {
      support: support,
    });
    return res.data;
  }
}

export default new SupportService();
