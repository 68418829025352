import api from "./api";
import { AxiosResponse } from "axios";

interface User {
  id: string;
}

interface UpdateData {
  name: string;
}

class UserService {
  // Получение данных текущего пользователя
  async me(): Promise<User> {
    try {
      const response: AxiosResponse<User> = await api.get("/auth/users/me");
      const userData = response.data;

      return userData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Обновление данных пользователя
  async patch(data: UpdateData): Promise<User> {
    try {
      const response: AxiosResponse<User> = await api.patch(
        "/auth/users/me/",
        data
      );
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.log(error);

      // Обработка ошибок, если нужно
      throw error;
    }
  }

  // Обновление аватара пользователя
  async updateAvatar(avatar: FormData): Promise<User> {
    try {
      const response: AxiosResponse<User> = await api.patch(
        "/auth/users/me",
        avatar,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default new UserService();
