<template>
  <div class="modal-backdrop">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Обращение в поддержку</h5>
        <button type="button" class="btn-close" @click="closeModal">x</button>
      </div>
      <div class="modal-body">
        <textarea
          class="form-control"
          rows="5"
          v-model="support_text"
          placeholder="Подробно опишите свою ситуацию, мы постараемся помочь, как можно скорее."
        ></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="sendMessage">
          Отправить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import SupportService from "@/services/support";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const support_text = ref(null);
    const closeModal = async () => {
      emit("close");
    };
    const sendMessage = async () => {
      // emit("close");
      await SupportService.createSupport(support_text);
    };
    return {
      closeModal,
      sendMessage,
    };
  },
});
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-close {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
}

textarea.form-control {
  width: 100%;
  margin-bottom: 20px;
}
</style>
