<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Связанные организации</h5>
      </div>
      <div class="card-body">
        <div class="filter-container d-flex align-items-center my-4">
          <label for="type-filter">Тип</label>
          <select
            id="type-filter"
            class="form-select w-auto ms-4"
            v-model="selectedType"
            @change="filterOrganizations"
          >
            <option value="Все">Все</option>
            <option value="Действующая">Действующая</option>
            <option value="Ликвидирована">Ликвидирована</option>
            <option value="Не действующая">Не действующая</option>
          </select>
        </div>
        <div
          class="connections-list"
          v-for="(company, index) in connections"
          :key="index"
        >
          <div class="connections-list__header">
            <h4>{{ company.name }}</h4>
            <span class="status" :class="getClass(company.status)">{{
              company.status
            }}</span>
          </div>
          <div class="connections-list__body">
            <p class="director">
              <!-- {{ org.director }} -->
            </p>
            <p class="address">
              {{ company.legal_address }}
            </p>
            <p class="details">
              <span>ИНН: </span>{{ company.inn }} | <span>ОГРН: </span
              >{{ company.ogrn }} | <span>Дата регистрации: </span
              >{{ company.registration_date }} |
              <!-- <span>Уставной капитал : {{ org.capital }}</span> -->
            </p>
            <p class="activity">
              <span>Основной вид деятельности:</span>
              {{ company.okved }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  name: "Connections",
  props: {
    connections: Array,
  },
  setup(props) {
    const selectedType = ref("Все");
    const filteredOrganizations = computed(() => {
      if (selectedType.value === "Все") {
        return props.connections;
      }
      return props.connections.filter(
        (org) => org.status === selectedType.value
      );
    });
    const getClass = (status) => {
      if (status === "Действует") {
        return "active";
      } else if (status === "Не действует") {
        return "inactive";
      } else {
        return "liquidated";
      }
    };

    return {
      selectedType,
      filteredOrganizations,
      getClass,
    };
  },
});
</script>
