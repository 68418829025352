<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Заключенные контракты</h5>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Роль</label>
            <select
              id="type-filter"
              class="form-select w-auto ms-4"
              v-model="selectedType"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Действующая">Действующая</option>
              <option value="Ликвидирована">Ликвидирована</option>
              <option value="Не действующая">Не действующая</option>
            </select>
          </div>

          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Роль</label>
            <select
              id="type-filter"
              class="form-select w-auto ms-4"
              v-model="selectedType"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Действующая">Действующая</option>
              <option value="Ликвидирована">Ликвидирована</option>
              <option value="Не действующая">Не действующая</option>
            </select>
          </div>

          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Предмет аренды</label>
            <select
              id="type-filter"
              class="form-select w-auto ms-4"
              v-model="selectedType"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Действующая">Действующая</option>
              <option value="Ликвидирована">Ликвидирована</option>
              <option value="Не действующая">Не действующая</option>
            </select>
          </div>
        </div>
        <div
          class="connections-list"
          v-for="contract in contracts"
          :key="contract.id"
        >
          <div class="connections-list__header">
            <p>
              № {{ contract.register_number }} от
              {{ formatDate(contract.date) }}
            </p>
            <!-- <span class="status liquidated">{{ org.status }}</span> -->
          </div>
          <div class="connections-list__body col-10">
            <p class="value">
              <span class="label">Сумма</span> {{ contract.price }}
            </p>
            <p class="value">
              <span class="label">Контрагент</span
              >{{ contract?.contract_provider[0]?.name }}
            </p>
            <p
              class="value"
              v-for="object in contract.purchase_objects"
              :key="object.id"
            >
              <span class="label">Предмет закупки</span> {{ object.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import moment from "moment";

export default defineComponent({
  name: "Contracts",
  props: {
    contracts: Array,
  },
  setup(props) {
    const selectedType = ref("Все");

    const filteredOrganizations = computed(() => {
      if (selectedType.value === "Все") {
        return props.contracts.value;
      }
      return props.contracts.value.filter(
        (org) => org.status === selectedType.value
      );
    });
    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };
    return {
      selectedType,
      filteredOrganizations,
      formatDate,
    };
  },
});
</script>
