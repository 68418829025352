<template>
  <div
    class="container counterparties"
    v-if="company && company !== null"
    :key="route.params.id"
  >
    <div class="d-flex flex-column sub-header mt-4">
      <div class="d-flex sub-header__title align-items-center">
        <div class="img"></div>
        <h3 class="ms-4">{{ company.name }}</h3>
        <span
          v-if="isLoggedIn"
          class="status ms-md-4"
          :class="getClass(company.status?.name)"
          >{{ company.status?.name }}</span
        >
        <button
          class="border-button btn-primary ms-auto py-1"
          @click="addFriend"
          v-if="isLoggedIn && !isFriend"
        >
          <img src="@/assets/images/add_icon.svg" />

          Добавить в друзья
        </button>
      </div>
      <div class="d-flex sub-header__navs">
        <ul class="nav nav-tabs w-100 justify-content-between mx-4">
          <li v-for="tab in tabs.slice(0, 9)" :key="tab.id" class="nav-item">
            <a
              :class="{ active: activeTab === tab.id }"
              :id="tab.id + '-tab'"
              data-bs-toggle="tab"
              :href="'#' + tab.id"
              role="tab"
              @click="setActiveTab(tab.id)"
              :aria-controls="'tab-' + tab.id"
              :aria-selected="activeTab === tab.id"
            >
              {{ tab.label }}
            </a>
          </li>

          <li class="nav-item dropdown" v-if="hiddenItems.length > 0">
            <a
              class="nav-link py-0 px-0 dropdown-toggle"
              href="#"
              role="button"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Ещё
            </a>
            <ul class="dropdown-menu">
              <li v-for="(hiddenItem, index) in hiddenItems" :key="index">
                <a
                  class="dropdown-item"
                  :class="{ active: activeTab === hiddenItem.id }"
                  :id="hiddenItem.id + '-tab'"
                  data-bs-toggle="tab"
                  :href="'#' + hiddenItem.id"
                  role="tab"
                  @click="setActiveTab(hiddenItem.id)"
                  :aria-controls="'tab-' + hiddenItem.id"
                  :aria-selected="activeTab === hiddenItem.id"
                  >{{ hiddenItem.label }}</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="/marketplace">Маркетплейс</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-4">
      <div class="tab-content" id="myTabContent">
        <!-- Первый таб -->
        <div
          class="tab-pane fade show active"
          id="tab-summary"
          role="tabpanel"
          aria-labelledby="summary-tab"
        >
          <Summary
            :company="company"
            :finances="companyFinances"
            :inspections="companyInspections"
            @change-tab="setActiveTab"
          />
        </div>

        <!-- Второй таб -->
        <div
          class="tab-pane fade"
          id="tab-arbitration"
          role="tabpanel"
          aria-labelledby="arbitration-tab"
        >
          <Arbitration :cases="companyLegalCases" />
        </div>

        <!-- Третий таб -->
        <div
          class="tab-pane fade"
          id="tab-finances"
          role="tabpanel"
          aria-labelledby="finances-tab"
        >
          <Finances :finances="companyFinances" />
        </div>

        <!-- Четвертый таб -->
        <div
          class="tab-pane fade"
          id="tab-connections"
          role="tabpanel"
          aria-labelledby="connections-tab"
        >
          <Connections :connections="company.LPEC" />
        </div>

        <!-- Пятый таб -->
        <div
          class="tab-pane fade"
          id="tab-trademarks"
          role="tabpanel"
          aria-labelledby="trademarks-tab"
        >
          <Trademarks :trademarks="company.trademarks" />
        </div>

        <!-- Шестой таб -->
        <div
          class="tab-pane fade"
          id="tab-founders"
          role="tabpanel"
          aria-labelledby="founders-tab"
        >
          <Founders :founders="companyFounders" />
        </div>

        <!-- Седьмой таб -->
        <div
          class="tab-pane fade"
          id="tab-reliability"
          role="tabpanel"
          aria-labelledby="reliability-tab"
        >
          <Analyze />
        </div>

        <!-- Восьмой таб -->
        <div
          class="tab-pane fade"
          id="tab-debts"
          role="tabpanel"
          aria-labelledby="debts-tab"
        >
          <Debts />
        </div>

        <!-- Девятый таб -->
        <div
          class="tab-pane fade"
          id="tab-sanctions"
          role="tabpanel"
          aria-labelledby="sanctions-tab"
        >
          <Sanctions
            :hasSanctions="company.sanctions"
            :sanctions_countries="company.country_sanctions"
          />
        </div>

        <!-- Десятый таб -->
        <div
          class="tab-pane fade"
          id="tab-leasing"
          role="tabpanel"
          aria-labelledby="leasing-tab"
        >
          <Leasing />
        </div>

        <!-- Одиннадцатый таб -->
        <div
          class="tab-pane fade"
          id="tab-inspections"
          role="tabpanel"
          aria-labelledby="inspections-tab"
        >
          <Inspections :inspections="companyInspections" />
        </div>

        <!-- Двенадцаты таб -->
        <div
          class="tab-pane fade"
          id="tab-history"
          role="tabpanel"
          aria-labelledby="history-tab"
        >
          <History />
        </div>

        <div
          class="tab-pane fade"
          id="tab-details"
          role="tabpanel"
          aria-labelledby="details-tab"
        >
          <Details :company="company" />
        </div>

        <div
          class="tab-pane fade"
          id="tab-okved"
          role="tabpanel"
          aria-labelledby="okved-tab"
        >
          <Okved :company="company" />
        </div>

        <div
          class="tab-pane fade"
          id="tab-branches"
          role="tabpanel"
          aria-labelledby="branches-tab"
        >
          <Branches
            :branches="
              company.division?.length ? company?.division[0]?.filials : []
            "
          />
        </div>

        <div
          class="tab-pane fade"
          id="tab-licenses"
          role="tabpanel"
          aria-labelledby="licenses-tab"
        >
          <Licenses :licenses="company.licenses" />
        </div>

        <div
          class="tab-pane fade"
          id="tab-licenses"
          role="tabpanel"
          aria-labelledby="licenses-tab"
        >
          <Contracts :contracts="companyContracts" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Summary from "../../components/CounterParties/Summary.vue";
import Connections from "../../components/CounterParties/Connections.vue";
import Analyze from "../../components/CounterParties/Analyze.vue";
import Founders from "../../components/CounterParties/Founders.vue";
import Finances from "../../components/CounterParties/Finances.vue";
import Arbitration from "@/components/CounterParties/Arbitration.vue";
import Sanctions from "@/components/CounterParties/Sanctions.vue";
import Debts from "@/components/CounterParties/Debts.vue";
import Leasing from "@/components/CounterParties/Leasing.vue";
import History from "@/components/CounterParties/History.vue";
import Trademarks from "@/components/CounterParties/Trademarks.vue";
import Okved from "@/components/CounterParties/Okved.vue";
import Details from "@/components/CounterParties/Details.vue";
import Licenses from "@/components/CounterParties/Licenses.vue";
import Contracts from "@/components/CounterParties/Contracts.vue";

import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Tab } from "bootstrap";
import Branches from "@/components/CounterParties/Branches.vue";
import Inspections from "@/components/CounterParties/Inspections.vue";
import CompanyService from "@/services/company";

export default defineComponent({
  name: "CounterParties",
  components: {
    Summary,
    Connections,
    Analyze,
    Founders,
    Finances,
    Arbitration,
    Contracts,
    Sanctions,
    Licenses,
    Branches,
    Inspections,
    Debts,
    Leasing,
    History,
    Trademarks,
    Okved,
    Details,
  },
  setup() {
    const tabs = [
      { id: "tab-summary", label: "Сводка", component: Summary },
      { id: "tab-arbitration", label: "Арбитраж", component: Arbitration },
      { id: "tab-finances", label: "Финансы", component: Finances },
      { id: "tab-connections", label: "Связи", component: Connections },
      { id: "tab-trademarks", label: "Товарные знаки", component: Trademarks },
      { id: "tab-founders", label: "Учредители", component: Founders },
      { id: "tab-reliability", label: "Надежность", component: Analyze },
      { id: "tab-debts", label: "Долги", component: Debts },
      { id: "tab-sanctions", label: "Санкции", component: Sanctions },
      { id: "tab-leasing", label: "Лизинг", component: Leasing },
      { id: "tab-inspections", label: "Проверки", component: Analyze },
      { id: "tab-history", label: "Истории", component: History },
      { id: "tab-details", label: "Реквизиты", component: Details },
      { id: "tab-okved", label: "Виды деятельности", component: Okved },
      { id: "tab-licenses", label: "Лицензии", component: Licenses },
      { id: "tab-branches", label: "Филиалы", component: Branches },
    ];
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(true);
    const isFriend = ref(false);
    const company = ref("");
    const companyFinances = ref([]);
    const companyFounders = ref([]);
    const companyLegalCases = ref([]);
    const companyContracts = ref([]);
    const companyInspections = ref([]);
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const activeTab = ref("tab-summary");

    const getClass = (status) => {
      if (status === "Действует") {
        return "active";
      } else if (status === "Не действует") {
        return "inactive";
      } else {
        return "liquidated";
      }
    };

    function setActiveTab(tabId) {
      activeTab.value = tabId;
      const tabTrigger = new Tab(document.querySelector(`#${tabId}-tab`));

      tabTrigger.show();
    }

    // Управление состоянием dropdown
    const isDropdownOpen = ref(false);
    const hiddenItems = computed(() => tabs.slice(9));

    function toggleDropdown() {
      isDropdownOpen.value = !isDropdownOpen.value;
    }

    const fetchData = async (id) => {
      isLoading.value = true;
      if (id) {
        try {
          let companyRes = await store.dispatch("companies/getByInn", id);
          companyFinances.value = await store.dispatch(
            "companies/getFinances",
            id
          );
          companyLegalCases.value = await store.dispatch(
            "companies/getLegalCases",
            id
          );
          companyContracts.value = await store.dispatch(
            "companies/getContracts",
            id
          );
          if (isLoggedIn.value) {
            companyInspections.value = await store.dispatch(
              "companies/getInspections",
              id
            );
            CompanyService.getFriends().then((res) => {
              res.map((item) => {
                if (item.friend.id == company.value.representative) {
                  isFriend.value = true;
                }
              });
            });
          }

          if (!companyRes) {
            companyRes = await store.dispatch("companies/getByIP", id);
          }
          company.value = companyRes;
          if (companyRes.founders && companyRes.founders?.length) {
            companyFounders.value = companyRes.founders[0]?.PP || [];
          }
          isLoading.value = false;
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
          isLoading.value = false;
        }
      } else {
        console.error("ID не найден в URL");
      }
    };

    const addFriend = () => {
      const form = ref({
        friend: company.value.representative,
      });

      CompanyService.addFriend(form.value).then(() => {
        isFriend.value = true;
        alert("Пользватель добавлен в друзья");
      });
    };

    onMounted(async () => {
      const id = route.params.id;
      fetchData(id);
    });
    watch(
      () => route.params.id,
      (newId) => {
        fetchData(newId);
      },
      { immediate: true }
    );

    return {
      company,
      companyFounders,
      companyFinances,
      companyLegalCases,
      companyContracts,
      companyInspections,
      tabs,
      hiddenItems,
      isDropdownOpen,
      route,
      activeTab,
      isLoggedIn,
      isFriend,
      setActiveTab,
      getClass,
      toggleDropdown,
      addFriend,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/counterparties/Summary.scss";
</style>
