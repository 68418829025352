import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import store from ".";
import AuthService from "../services/auth";

interface UserToken {
  accessToken: string;
  refresh: string;
}

interface AuthState {
  status: {
    loggedIn: boolean;
  };
  userToken: UserToken | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RootState {
  // Определите тип корневого состояния, если у вас есть другие состояния
}

const userToken: UserToken | null = JSON.parse(
  localStorage.getItem("userToken") || "null"
);

const initialState: AuthState = userToken
  ? { status: { loggedIn: true }, userToken }
  : { status: { loggedIn: false }, userToken: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    // Логин
    async login(
      { commit }: ActionContext<AuthState, RootState>,
      user: { email: string; password: string }
    ) {
      try {
        const userToken = await AuthService.login(user);
        commit("loginSuccess", userToken);
        await store.dispatch("user/me");
        // store.dispatch("getCartItems");
        return Promise.resolve(userToken);
      } catch (error) {
        commit("loginFailure");
        return Promise.reject(error);
      }
    },

    // Выход
    logout({ commit }: ActionContext<AuthState, RootState>) {
      AuthService.logout();
      commit("logout");
    },

    // Регистрация
    async register(
      { commit }: ActionContext<AuthState, RootState>,
      userToken: UserToken
    ) {
      try {
        const response = await AuthService.register(userToken);
        commit("registerSuccess");
        await store.dispatch("me");
        store.dispatch("getCartItems");
        return Promise.resolve(response.data);
      } catch (error) {
        commit("registerFailure");
        return Promise.reject(error);
      }
    },

    registration(data: any) {
      return AuthService.registration(data);
    },

    // Обновление токена
    refreshToken(
      { commit }: ActionContext<AuthState, RootState>,
      accessToken: string
    ) {
      commit("refreshToken", accessToken);
    },
  } as ActionTree<AuthState, RootState>,

  mutations: {
    loginSuccess(state: AuthState, userToken: UserToken) {
      state.status.loggedIn = true;
      state.userToken = userToken;
    },
    loginFailure(state: AuthState) {
      state.status.loggedIn = false;
      state.userToken = null;
    },
    logout(state: AuthState) {
      state.status.loggedIn = false;
      state.userToken = null;
      localStorage.removeItem("userToken");
      localStorage.removeItem("user");
    },
    registerSuccess(state: AuthState) {
      state.status.loggedIn = false;
    },
    registerFailure(state: AuthState) {
      state.status.loggedIn = false;
    },

    refreshToken(state: AuthState, accessToken: string) {
      state.status.loggedIn = true;
      if (state.userToken) {
        state.userToken = { ...state.userToken, accessToken };
      }
    },
  } as MutationTree<AuthState>,

  getters: {
    // Определите геттеры, если они нужны
  } as GetterTree<AuthState, RootState>,
};
