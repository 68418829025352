<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Отчеты и документы</h5>
      <ul class="info-list">
        <li class="d-flex flex-row align-items-center border-bottom">
          <svg
            class="me-2"
            width="25"
            height="29"
            viewBox="0 0 25 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.1538 0C21.4243 0 21.6747 0.0471986 21.905 0.141596C22.1354 0.235993 22.3357 0.363429 22.506 0.523905C22.6763 0.68438 22.8165 0.877894 22.9267 1.10445C23.0369 1.331 23.0869 1.56699 23.0769 1.81243V15.8587C23.0769 16.1514 23.122 16.4062 23.2121 16.6233C23.3023 16.8405 23.4225 17.0481 23.5727 17.2464C23.723 17.4446 23.8782 17.6381 24.0385 17.8269C24.1987 18.0157 24.354 18.2281 24.5042 18.4641C24.6544 18.7001 24.7696 18.9738 24.8498 19.2853C24.9299 19.5969 24.98 19.965 25 20.3898V27.1864C25 27.4413 24.9499 27.6773 24.8498 27.8944C24.7496 28.1115 24.6144 28.3003 24.4441 28.4608C24.2738 28.6212 24.0685 28.7534 23.8281 28.8572C23.5877 28.9611 23.3373 29.0083 23.0769 28.9988H0V0H21.1538ZM1.92308 27.1864H19.2308V20.3898C19.2308 19.965 19.2758 19.6016 19.366 19.2995C19.4561 18.9974 19.5763 18.7237 19.7266 18.4783C19.8768 18.2328 20.0321 18.0157 20.1923 17.8269C20.3526 17.6381 20.5078 17.4493 20.6581 17.2605C20.8083 17.0717 20.9235 16.8641 21.0036 16.6375C21.0837 16.411 21.1338 16.1514 21.1538 15.8587V1.81243H1.92308V27.1864ZM23.0769 20.3898C23.0769 20.1632 23.0569 19.965 23.0168 19.7951C22.9768 19.6252 22.9117 19.4694 22.8215 19.3278C22.7314 19.1862 22.6312 19.0494 22.521 18.9172C22.4109 18.785 22.2756 18.634 22.1154 18.4641C21.9651 18.6246 21.8349 18.7709 21.7248 18.903C21.6146 19.0352 21.5144 19.1768 21.4243 19.3278C21.3341 19.4789 21.269 19.6346 21.229 19.7951C21.1889 19.9556 21.1639 20.1538 21.1538 20.3898V27.1864H23.0769V20.3898Z"
              fill="#0066CE"
            />
          </svg>

          <span class="info-value"
            >Выписка из ЕГРЮЛ<br /><span class="info-value__subtext"
              >С подписью ФНС на нужную дату</span
            ></span
          >
        </li>
        <li class="d-flex flex-row align-items-center border-bottom">
          <svg
            class="me-2"
            width="25"
            height="29"
            viewBox="0 0 25 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.1538 0C21.4243 0 21.6747 0.0471986 21.905 0.141596C22.1354 0.235993 22.3357 0.363429 22.506 0.523905C22.6763 0.68438 22.8165 0.877894 22.9267 1.10445C23.0369 1.331 23.0869 1.56699 23.0769 1.81243V15.8587C23.0769 16.1514 23.122 16.4062 23.2121 16.6233C23.3023 16.8405 23.4225 17.0481 23.5727 17.2464C23.723 17.4446 23.8782 17.6381 24.0385 17.8269C24.1987 18.0157 24.354 18.2281 24.5042 18.4641C24.6544 18.7001 24.7696 18.9738 24.8498 19.2853C24.9299 19.5969 24.98 19.965 25 20.3898V27.1864C25 27.4413 24.9499 27.6773 24.8498 27.8944C24.7496 28.1115 24.6144 28.3003 24.4441 28.4608C24.2738 28.6212 24.0685 28.7534 23.8281 28.8572C23.5877 28.9611 23.3373 29.0083 23.0769 28.9988H0V0H21.1538ZM1.92308 27.1864H19.2308V20.3898C19.2308 19.965 19.2758 19.6016 19.366 19.2995C19.4561 18.9974 19.5763 18.7237 19.7266 18.4783C19.8768 18.2328 20.0321 18.0157 20.1923 17.8269C20.3526 17.6381 20.5078 17.4493 20.6581 17.2605C20.8083 17.0717 20.9235 16.8641 21.0036 16.6375C21.0837 16.411 21.1338 16.1514 21.1538 15.8587V1.81243H1.92308V27.1864ZM23.0769 20.3898C23.0769 20.1632 23.0569 19.965 23.0168 19.7951C22.9768 19.6252 22.9117 19.4694 22.8215 19.3278C22.7314 19.1862 22.6312 19.0494 22.521 18.9172C22.4109 18.785 22.2756 18.634 22.1154 18.4641C21.9651 18.6246 21.8349 18.7709 21.7248 18.903C21.6146 19.0352 21.5144 19.1768 21.4243 19.3278C21.3341 19.4789 21.269 19.6346 21.229 19.7951C21.1889 19.9556 21.1639 20.1538 21.1538 20.3898V27.1864H23.0769V20.3898Z"
              fill="#0066CE"
            />
          </svg>
          <span class="info-value"
            >Бухгалтерская отчетность<br /><span class="info-value__subtext"
              >С подписью ФНС на нужную дату</span
            ></span
          >
        </li>
        <li class="d-flex flex-row align-items-center border-bottom">
          <svg
            class="me-2"
            width="25"
            height="29"
            viewBox="0 0 25 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.1538 0C21.4243 0 21.6747 0.0471986 21.905 0.141596C22.1354 0.235993 22.3357 0.363429 22.506 0.523905C22.6763 0.68438 22.8165 0.877894 22.9267 1.10445C23.0369 1.331 23.0869 1.56699 23.0769 1.81243V15.8587C23.0769 16.1514 23.122 16.4062 23.2121 16.6233C23.3023 16.8405 23.4225 17.0481 23.5727 17.2464C23.723 17.4446 23.8782 17.6381 24.0385 17.8269C24.1987 18.0157 24.354 18.2281 24.5042 18.4641C24.6544 18.7001 24.7696 18.9738 24.8498 19.2853C24.9299 19.5969 24.98 19.965 25 20.3898V27.1864C25 27.4413 24.9499 27.6773 24.8498 27.8944C24.7496 28.1115 24.6144 28.3003 24.4441 28.4608C24.2738 28.6212 24.0685 28.7534 23.8281 28.8572C23.5877 28.9611 23.3373 29.0083 23.0769 28.9988H0V0H21.1538ZM1.92308 27.1864H19.2308V20.3898C19.2308 19.965 19.2758 19.6016 19.366 19.2995C19.4561 18.9974 19.5763 18.7237 19.7266 18.4783C19.8768 18.2328 20.0321 18.0157 20.1923 17.8269C20.3526 17.6381 20.5078 17.4493 20.6581 17.2605C20.8083 17.0717 20.9235 16.8641 21.0036 16.6375C21.0837 16.411 21.1338 16.1514 21.1538 15.8587V1.81243H1.92308V27.1864ZM23.0769 20.3898C23.0769 20.1632 23.0569 19.965 23.0168 19.7951C22.9768 19.6252 22.9117 19.4694 22.8215 19.3278C22.7314 19.1862 22.6312 19.0494 22.521 18.9172C22.4109 18.785 22.2756 18.634 22.1154 18.4641C21.9651 18.6246 21.8349 18.7709 21.7248 18.903C21.6146 19.0352 21.5144 19.1768 21.4243 19.3278C21.3341 19.4789 21.269 19.6346 21.229 19.7951C21.1889 19.9556 21.1639 20.1538 21.1538 20.3898V27.1864H23.0769V20.3898Z"
              fill="#0066CE"
            />
          </svg>
          <span class="info-value"
            >Отчет о должностной осмотритеотности <br /><span
              class="info-value__subtext"
              >По методологии ФНС
            </span></span
          >
        </li>
        <li class="d-flex flex-row align-items-center border-bottom">
          <svg
            class="me-2"
            width="25"
            height="29"
            viewBox="0 0 25 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.1538 0C21.4243 0 21.6747 0.0471986 21.905 0.141596C22.1354 0.235993 22.3357 0.363429 22.506 0.523905C22.6763 0.68438 22.8165 0.877894 22.9267 1.10445C23.0369 1.331 23.0869 1.56699 23.0769 1.81243V15.8587C23.0769 16.1514 23.122 16.4062 23.2121 16.6233C23.3023 16.8405 23.4225 17.0481 23.5727 17.2464C23.723 17.4446 23.8782 17.6381 24.0385 17.8269C24.1987 18.0157 24.354 18.2281 24.5042 18.4641C24.6544 18.7001 24.7696 18.9738 24.8498 19.2853C24.9299 19.5969 24.98 19.965 25 20.3898V27.1864C25 27.4413 24.9499 27.6773 24.8498 27.8944C24.7496 28.1115 24.6144 28.3003 24.4441 28.4608C24.2738 28.6212 24.0685 28.7534 23.8281 28.8572C23.5877 28.9611 23.3373 29.0083 23.0769 28.9988H0V0H21.1538ZM1.92308 27.1864H19.2308V20.3898C19.2308 19.965 19.2758 19.6016 19.366 19.2995C19.4561 18.9974 19.5763 18.7237 19.7266 18.4783C19.8768 18.2328 20.0321 18.0157 20.1923 17.8269C20.3526 17.6381 20.5078 17.4493 20.6581 17.2605C20.8083 17.0717 20.9235 16.8641 21.0036 16.6375C21.0837 16.411 21.1338 16.1514 21.1538 15.8587V1.81243H1.92308V27.1864ZM23.0769 20.3898C23.0769 20.1632 23.0569 19.965 23.0168 19.7951C22.9768 19.6252 22.9117 19.4694 22.8215 19.3278C22.7314 19.1862 22.6312 19.0494 22.521 18.9172C22.4109 18.785 22.2756 18.634 22.1154 18.4641C21.9651 18.6246 21.8349 18.7709 21.7248 18.903C21.6146 19.0352 21.5144 19.1768 21.4243 19.3278C21.3341 19.4789 21.269 19.6346 21.229 19.7951C21.1889 19.9556 21.1639 20.1538 21.1538 20.3898V27.1864H23.0769V20.3898Z"
              fill="#0066CE"
            />
          </svg>
          <span class="info-value"
            >Сводный отчет <br /><span class="info-value__subtext"
              >Общие сведение об организации</span
            ></span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentsInfo",
};
</script>
