class TokenService {
  private storageKey = "userToken";

  private getStoredUser(): { access?: string; refresh?: string } | null {
    const userToken = localStorage.getItem(this.storageKey);
    return userToken ? JSON.parse(userToken) : null;
  }

  getLocalRefreshToken(): string | undefined {
    const user = this.getStoredUser();
    return user?.refresh;
  }

  getLocalAccessToken(): string | undefined {
    const user = this.getStoredUser();
    return user?.access;
  }

  updateLocalAccessToken(token: string, tokenRef: string): void {
    const user = this.getStoredUser() || {};
    user.access = token;
    user.refresh = tokenRef;
    localStorage.setItem(this.storageKey, JSON.stringify(user));
  }

  getUser(): { access?: string; refresh?: string } | null {
    return this.getStoredUser();
  }

  setUser(userToken: { access: string; refresh: string }): void {
    localStorage.setItem(this.storageKey, JSON.stringify(userToken));
  }

  removeUser(): void {
    localStorage.removeItem(this.storageKey);
    localStorage.removeItem("user");
  }
}

export default new TokenService();
