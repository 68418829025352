<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Риски сотрудничества</h5>
      <ul class="info-list mt-2">
        <li class="border-bottom py-2">
          <span class="info-title">Банкротство</span>
          <span class="info-value"
            >Проверьте наличие сведений о банкротстве юридического лица
          </span>
          <button class="btn-primary btn-small d-block ms-auto mt-2">
            Подробнее
          </button>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <span class="info-title">Банковские счета</span>
          <span class="info-value"
            >Проверить наличие решений о блокировке банковских счетов налоговыми
            органами
          </span>
          <button class="btn-primary btn-small d-block ms-auto mt-2">
            Подробнее
          </button>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <span class="info-title">Обеспечительные меры</span>
          <span class="info-value"
            >Применяются ли обеспечительные меры в отношении организации за
            налоговые правонарушения?
          </span>
          <button class="btn-primary btn-small d-block ms-auto mt-2">
            Подробнее
          </button>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <span class="info-title">Санкционные списки</span>
          <span class="info-value"
            >Проверка нахождения организации и связанных с ней физических и
            юридических лиц в санкционных списках
          </span>
          <button class="btn-primary btn-small d-block ms-auto mt-2">
            Подробнее
          </button>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <span class="info-title">Документы для госрегистрации</span>
          <span class="info-value"
            >Сведения о заявлениях о ликвидации, реорганизации, смене адреса,
            руководителя или уставного капитала
          </span>
          <button class="btn-primary btn-small d-block ms-auto mt-2">
            Подробнее
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RisksInfo",
};
</script>
