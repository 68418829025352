
import { features } from "@/models/main-page";
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  setup() {
    return {
      features,
    };
  },
});
