<template>
  <div>
    <h5 class="modal-title" id="authModalLabel">Регистрация</h5>

    <form @submit.prevent="register" class="my-4" v-if="!isRegisetred">
      <div class="mb-3">
        <label for="username" class="form-label">Имя</label>
        <input
          type="text"
          class="form-control"
          id="username"
          v-model="form.full_name"
          required
        />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">E-mail</label>
        <input
          type="text"
          class="form-control"
          id="email"
          v-model="form.email"
          required
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Пароль</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="form.password"
          required
        />
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="acceptTerms"
          v-model="form.acceptTerms"
          required
        />
        <label
          class="form-check-label"
          for="acceptTerms"
          style="font-size: 15px"
        >
          Я принимаю <a href="#">условия сервиса</a> и согласен на обработку
          <a href="#">персональных данных.</a>
        </label>
      </div>

      <button
        type="submit"
        class="btn btn-primary w-100"
        :disabled="!isFormValid"
      >
        Зарегистрироваться
      </button>
    </form>
    <template v-else>
      <p>Вы успешно зарегистрировались</p>
    </template>
    <div class="divider"></div>
    <!-- </form> -->
    <p class="mt-3 mb-1">У вас уже есть аккаунт?</p>
    <button type="button" class="btn btn-link" @click="switchView('AuthView')">
      Войти
    </button>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import AuthService from "../services/auth";

export default {
  name: "RegisterView",
  setup(props, { emit }) {
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const isRegisetred = ref("");
    const form = ref({
      full_name: "",
      email: "",
      password: "",
      acceptTerms: "",
    });

    const register = () => {
      if (!form.value.acceptTerms) {
        alert("Пожалуйста, примите условия.");
        return;
      }
      const data = {
        full_name: form.value.full_name,
        email: form.value.email,
        password: form.value.password,
      };
      AuthService.registration(data)
        .then((response) => {
          isRegisetred.value = true;

          return Promise.resolve(response);
        })
        .catch((error) => {
          if (
            error.response.data.email[0] ===
            "Пользователь with this Электронная почта already exists."
          ) {
            alert("Пользватель с такой почтой уже зарегестрирован");
          }
          return Promise.reject(error);
        });
    };

    const switchView = (view) => {
      emit("switchView", view);
    };
    // Проверка валидности формы
    const isFormValid = computed(() => {
      return (
        form.value.full_name &&
        form.value.email &&
        form.value.password &&
        form.value.acceptTerms
      );
    });

    return {
      isRegisetred,
      form,
      username,
      email,
      password,
      isFormValid,
      register,
      switchView,
    };
  },
};
</script>
