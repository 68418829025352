/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionContext } from "vuex";
import CompanyService from "../services/company";
// import { RootState } from "../models/types";
import MarketplaceService from "@/services/marketplace";

interface CompaniesState {
  companies: any[] | null;
  company: any | null;
}
interface RootState {
  companyData: any;
  loading: boolean;
  error: any;
}
const state: CompaniesState = {
  companies: null,
  company: null,
};

export const companies = {
  namespaced: true,

  state,

  actions: {
    // Поиск компаний
    async getCompanies(
      { commit }: ActionContext<CompaniesState, RootState>,
      query: string
    ) {
      try {
        const res = await CompanyService.searchCompanies(query);
        commit("searchSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("searchFailure");
        return Promise.reject(error);
      }
    },
    async searchCompanies(
      { commit }: ActionContext<CompaniesState, RootState>,
      query: string
    ) {
      try {
        const res = await CompanyService.companySearch(query);
        commit("searchSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("searchFailure");
        return Promise.reject(error);
      }
    },
    async searchProducts(
      { commit }: ActionContext<CompaniesState, RootState>,
      query: string
    ) {
      try {
        const res = await MarketplaceService.productsSearch(query);
        commit("searchSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("searchFailure");
        return Promise.reject(error);
      }
    },
    async getById(
      { commit }: ActionContext<CompaniesState, RootState>,
      id: number
    ) {
      try {
        const res = await CompanyService.getById(id);
        commit("getByInnSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getByInn(
      { commit }: ActionContext<CompaniesState, RootState>,
      inn: string
    ) {
      try {
        const res = await CompanyService.getByInn(inn);
        commit("getByInnSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getFinances(
      { commit }: ActionContext<CompaniesState, RootState>,
      inn: string
    ) {
      try {
        const res = await CompanyService.getFinances(inn);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getLegalCases(
      { commit }: ActionContext<CompaniesState, RootState>,
      inn: string
    ) {
      try {
        const res = await CompanyService.getLegalCases(inn);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getContracts(
      { commit }: ActionContext<CompaniesState, RootState>,
      inn: string
    ) {
      try {
        const res = await CompanyService.getContracts(inn);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getInspections(
      { commit }: ActionContext<CompaniesState, RootState>,
      inn: string
    ) {
      try {
        const res = await CompanyService.getInspections(inn);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
    async getByIP(
      { commit }: ActionContext<CompaniesState, RootState>,
      ip: string
    ) {
      try {
        const res = await CompanyService.getByIP(ip);
        commit("getByInnSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("getByInnFailure");
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    searchSuccess(state: CompaniesState, companies: any) {
      state.companies = companies;
    },
    searchFailure(state: CompaniesState) {
      state.companies = null;
    },
    getByInnSuccess(state: CompaniesState, company: any) {
      state.company = company;
    },
    getByInnFailure(state: CompaniesState) {
      state.company = null;
    },
  },
};
