<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Учредители</h5>
      </div>
      <div class="card-body" v-if="isLoggedIn">
        <div class="d-flex justify-content-between">
          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Период</label>
            <input
              type="date"
              id="period-filter"
              class="form-select w-auto ms-4"
              v-model="selectedPeriod"
              @change="filterOrganizations"
            />
          </div>

          <!-- <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Тип</label>
            <select
              id="type-filter"
              class="form-select w-auto ms-4"
              v-model="selectedPeriod"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Действующая">Действующая</option>
              <option value="Ликвидирована">Ликвидирована</option>
              <option value="Не действующая">Не действующая</option>
            </select>
          </div> -->

          <!-- <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Статус</label>
            <select
              id="type-filter"
              class="form-select w-auto ms-4"
              v-model="selectedStatus"
              @change="filterOrganizations"
            >
              <option value="Все">Все</option>
              <option value="Действующая">Действующая</option>
              <option value="Ликвидирована">Ликвидирована</option>
              <option value="Не действующая">Не действующая</option>
            </select>
          </div> -->
        </div>
        <div v-if="founders && founders.length">
          <div
            class="connections-list"
            v-for="(founder, index) in founders"
            :key="index"
          >
            <div class="connections-list__header">
              <h4>Актуальные</h4>
            </div>
            <div
              class="connections-list__body d-flex justify-content-between col-10"
            >
              <p class="value">{{ founder.fio }}</p>
              <span class="label">Дата</span>
              <p class="value">{{ founder.record_date }}</p>
              <span class="label">Доля</span>
              <p class="value">{{ founder.fraction.percent }}</p>
              <span class="label">ИНН</span>
              <p class="value">{{ founder.inn }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Founders",
  props: {
    founders: Object,
  },
  setup(props) {
    const selectedType = ref("Все");
    const selectedPeriod = ref("Все");
    const selectedStatus = ref("Все");
    const store = useStore();
    const isLoggedIn = store.state.auth.status.loggedIn;

    const filteredFounders = computed(() => {
      if (isLoggedIn) {
        return props.founders.value.PP?.filter((founder) => {
          const periodMatch =
            selectedPeriod.value === "Все" ||
            founder.record_date === selectedPeriod.value;
          const typeMatch =
            selectedType.value === "Все" ||
            founder.fraction.percent === selectedType.value;
          const statusMatch =
            selectedStatus.value === "Все" ||
            founder.inn === selectedStatus.value;

          return periodMatch && typeMatch && statusMatch;
        });
      } else {
        return [];
      }
    });

    return {
      isLoggedIn,
      selectedType,
      selectedPeriod,
      selectedStatus,
      filteredFounders,
    };
  },
});
</script>
