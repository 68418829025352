<template>
  <div class="profile pb-4">
    <div class="card profile-info">
      <div class="card-body">
        <h2>Друзья</h2>
        <div class="accordion mt-4" id="accordionOne">
          <div
            class="accordion-item px-2"
            v-for="(friend, index) in friends"
            :key="index"
            :id="'heading' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse'"
                :data-bs-target="'#collapse' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse' + index"
              >
                {{ friend.title }}
              </button>
            </h2>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#headingOne"
            >
              <div class="accordion-body">
                {{ friend.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Friends",
  setup() {
    const friends = ref([
      {
        title: "ООО “Тундра”",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "ООО “Тундра”",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);
    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }
    return { friends, isCollapsed, toggleCollapse };
  },
});
</script>
