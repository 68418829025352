<template>
  <div class="profile cart pb-4">
    <div class="card profile-info">
      <div class="card-body">
        <h2>Карточка товара</h2>
        <button class="grey-button my-4" @click="addProduct()">
          Создать карточку
        </button>
        <h2 class="mt-4">Мои карточки</h2>
        <div class="products-container mt-4">
          <div class="product-list row gy-4">
            <NewProductCard
              class="mt-4"
              v-for="(item, index) in products.result"
              :key="index"
              :product="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import NewProductCard from "../../components/Profile/NewProductCard.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import MarketplaceService from "../../services/marketplace";

export default defineComponent({
  name: "Products",
  components: {
    NewProductCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loggedIn = computed(() => store.state.auth.status.loggedIn);
    const products = ref([]);
    const isLoading = ref(false);

    const route = useRoute();
    const mode = ref("products");

    const getProducts = async () => {
      isLoading.value = true;
      const me = await store.dispatch("user/me");

      MarketplaceService.companyRepresentative(
        me.id,
        route.query.page || 1
      ).then((res) => {
        products.value = res;
        isLoading.value = false;
      });
    };

    const addProduct = () => {
      router.push("/profile/products/add");
    };

    onMounted(async () => {
      if (!loggedIn.value) {
        router.push("/login");
      } else {
        getProducts();

        isLoading.value = true;
      }
    });

    const selectMode = (select) => {
      mode.value = select;
      getProducts();
    };

    watch(route, () => {
      getProducts();
    });

    return {
      products,
      selectMode,
      getProducts,
      addProduct,
    };
  },
});
</script>
