<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Долги</h5>
      <span>Сведения о наличии исполнительных производств в отношении</span>
      <a href="#">ПАО “Уриал”</a>
      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-title">Производств</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">На сумму </span
          ><span class="info-bold text-end col-3">390 млр. р.</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Штрафы : </span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Остаток задолженности</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Прочее :</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-reliability')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DebtsInfo",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      emitChangeTab,
    };
  },
};
</script>
