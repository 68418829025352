<template>
  <canvas id="myGauge" width="255" height="200"></canvas>
</template>

<script>
export default {
  name: "Gauge",
};
</script>
<script setup>
import { ref, onMounted } from "vue";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from "chart.js";

// Регистрация необходимых компонентов Chart.js
Chart.register(ArcElement, Tooltip, Legend, DoughnutController);

// Плагин для рисования стрелки
const arrowPlugin = {
  id: "arrowPlugin",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    const centerX = width / 2;
    const centerY = height / 1.3; // Нижний центр диаграммы
    const length = width * 0.35; // Длина стрелки
    const angle = Math.PI * (1 - chart.data.datasets[0]?.data[0] / 100); // Угол поворота стрелки
    const arrowWidth = 10; // Ширина стрелки
    const baseRadius = 5; // Радиус круглого основания

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(-Math.PI / 2 + angle);

    // Рисуем круглое основание
    ctx.beginPath();
    ctx.arc(0, 0, baseRadius, 0, Math.PI * 2);
    ctx.fillStyle = "#3E3E3E";
    ctx.fill();

    // Очистка перед рисованием
    ctx.beginPath();
    ctx.moveTo(0, -length); // Верхняя точка треугольника
    ctx.lineTo(arrowWidth / 2, 0); // Правая точка
    ctx.lineTo(-arrowWidth / 2, 0); // Левая точка
    ctx.closePath();
    ctx.fillStyle = "#3E3E3E"; // Цвет стрелки
    ctx.fill();

    ctx.restore();
  },
};

onMounted(() => {
  const ctx = document.getElementById("myGauge")?.getContext("2d");
  new Chart(ctx, {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [33, 33, 33],
          backgroundColor: ["#D442334D", "#E490334D", "#58B2514D"],
          borderColor: ["#D44233", "#E49033", "#58B251"], // Цвет границ
          borderWidth: 3, // Ширина границ
          spacing: 24,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutout: "85%", // Внутренний радиус
      rotation: -90, // Начальный угол диаграммы (в градусах)
      circumference: 180, // Устанавливаем половину окружности (в градусах)
      plugins: {
        legend: {
          display: false, // Отключаем легенду для простоты
        },
        tooltip: {
          enabled: false, // Отключаем тултипы для простоты
        },
      },
      elements: {
        arc: {
          //   borderWidth: 3,
          borderRadius: 10, // Округление сегментов
          //   borderColor: 'red'
        },
      },
    },
    plugins: [arrowPlugin], // Добавляем плагин
  });
});
</script>
