<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Связи</h5>
      <span
        >Выявлено 17 действующих и 13 ликвидированных связанных
        организаций</span
      >
      <ul class="info-list mt-2">
        <li>
          <span class="info-title">Действующие :</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Всего</span><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">По адресу</span
          ><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">По учредителю</span
          ><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Дочерние</span
          ><span class="info-bold">15</span>
        </li>
        <li class="pt-3">
          <span class="info-title">Прошлые:</span>
        </li>

        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Всего</span><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">По адресу</span
          ><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">По учредителю</span
          ><span class="info-bold">15</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Дочерние</span
          ><span class="info-bold">15</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-connections')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConnectionsInfo",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      emitChangeTab,
    };
  },
};
</script>
