<template>
  <div
    class="modal fade auth-modal"
    id="authModal"
    tabindex="-1"
    aria-labelledby="authModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="@/assets/images/logo.svg" alt="Info Global Logo" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <component
            :is="currentView"
            @switchView="switchView"
            @close="closeModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from "vue";
import AuthView from "../AuthView.vue";
import ForgotPasswordView from "../ForgotPasswordView.vue";
import RegisterView from "../RegisterView.vue";

export default defineComponent({
  name: "AuthModal",
  components: {
    AuthView,
    ForgotPasswordView,
    RegisterView,
  },
  setup() {
    const currentView = ref("AuthView");
    const closeButton = ref(null);

    const switchView = (view) => {
      currentView.value = view;
    };
    const closeModal = () => {
      closeButton.value.click();
    };

    return {
      currentView,
      closeButton,
      switchView,
      closeModal,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/auth.scss";
</style>
