<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Госзакупки</h5>
      <ul class="info-list mt-2">
        <li class="border-bottom py-2">
          <a href="#">ООО “Глинка”</a>
          <span class="info-value">345 контрактов на сумму 456 млрд. р. </span>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <a href="#">ООО “Глинка”</a>
          <span class="info-value">5 контрактов на сумму 5386 млр. р. </span>
        </li>
        <li class="border-bottom pt-3 pb-2">
          <a href="#">ООО “Глинка”</a>
          <span class="info-value">5 контрактов на сумму 5386 млр. р. </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchasesInfo",
};
</script>
