import axios, { AxiosInstance } from "axios";

// Создание экземпляра axios с типизацией
const checkoInstance: AxiosInstance = axios.create({
  baseURL: "https://api.checko.ru/v2",
  headers: {
    "Content-Type": "application/json",
  },
});

export default checkoInstance;
