import api from "./api";

class productCategoriesService {
  all() {
    return api.get("/api/v1/categories/").then((res) => {
      return res.data;
    });
  }
  tags() {
    return api.get("/api/v1/tags/").then((res) => {
      return res.data;
    });
  }
}

export default new productCategoriesService();
