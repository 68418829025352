<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Учредители</h5>
      <span
        >Согласно данным ЕГРЮЛ учредителем ООО "Тундра" является
        {{ founders.length }} российское юридическое лицо:</span
      >
      <ul class="info-list mt-2" v-for="founder in founders" :key="founder.id">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Доля</span
          ><span class="col-8 info-bold text-end">{{
            founder.fraction.percent
          }}</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">ИНН</span
          ><span class="col-8 info-bold text-end">{{ founder.inn }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoundersInfo",
  props: {
    founders: Array,
  },
};
</script>
