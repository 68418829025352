<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Краткая сводка</h5>
      <span
        >Торговый Дом "Спорт" город Пермь, Комсомольский пр-кт, д.55
        зарегистрирована 18.09.2002 регистратором МЕЖРАЙОННАЯ ИНСПЕКЦИЯ
        ФЕДЕРАЛЬНОЙ НАЛОГОВОЙ СЛУЖБЫ № 17 ПО ПЕРМСКОМУ КРАЮ. Руководитель
        организации: директор Садартинов Сергей Геннадьевич. Основным видом
        деятельности является зарегистрированы 2 дополнительных вида
        деятельности. Организации ТОВАРИЩЕСТВО НА ВЕРЕ "ТОРГОВЫЙ ДОМ "СПОРТ"
        САДАРТИНОВ И К" присвоены ИНН 5904011340, ОГРН 1025900890927, ОКПО
        24061443. Телефон, адрес электронной почты, адрес официального сайта и
        другие контактные данные Торговый Дом "Спорт" отсутствуют в ЕГРЮЛ и
        могут быть добавлены представителем организации.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortInfo",
};
</script>
