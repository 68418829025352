
import { defineComponent, Ref, ref } from "vue";
import { tariffs } from "@/models/main-page";
import TariffModal from "../Modals/TariffModal.vue";

export default defineComponent({
  components: { TariffModal },
  name: "Pricing",
  setup() {
    let type: Ref<string> = ref("monthly");

    const setType = (value: string) => {
      type.value = value;
    };

    return {
      type,
      setType,
      tariffs,
    };
  },
});
