<template>
  <div
    class="profile-page d-flex flex-md-row flex-column px-4 py-4 mb-4"
    v-if="isLoggedIn"
  >
    <ProfileSidebar />
    <main class="content ps-md-4 mt-md-0 mt-4">
      <router-view />
    </main>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import ProfileSidebar from "@/components/Profile/Sidebar.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Profile",
  components: { ProfileSidebar },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const router = useRouter();

    onMounted(() => {
      if (!isLoggedIn.value) {
        router.push("/");
      }
    });

    return {
      isLoggedIn,
    };
  },
});
</script>

<style>
@import "../assets/styles/Profile.scss";
</style>
