
import { defineComponent, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import Burger from "./Burger.vue";
import AuthModal from "../components/Modals/AuthModal.vue";

export default defineComponent({
  components: { Burger, AuthModal },
  name: "Header",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const currentUser = computed(() => store.state.user.user);

    const scrollTo = (section: string) => {
      const element = document.getElementById(section);
      element?.scrollIntoView({ behavior: "smooth" });
    };

    watchEffect(() => {
      if (isLoggedIn.value && !currentUser.value) {
        store.dispatch("user/me");
      }
    });
    return {
      currentUser,
      isLoggedIn,
      scrollTo,
    };
  },
});
