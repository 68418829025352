// setup.ts
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import axiosInstance from "./api";
import TokenService from "./token";
import router from "../router";
import store from "../store";

const setup = () => {
  // Интерцептор запросов
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const accessToken = TokenService.getLocalAccessToken();
      if (accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  // Интерцептор ответов
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const originalConfig = error.config as any;

      // Проверка на ошибку 401 и повторный запрос
      if (
        error.response?.status === 401 &&
        !originalConfig._retry &&
        originalConfig.url !== "/auth/jwt/create"
      ) {
        originalConfig._retry = true;

        try {
          const refreshToken = TokenService.getLocalRefreshToken();

          store.dispatch("auth/logout");
          if (!refreshToken) {
            alert(
              "Вам нужно войти в аккаунт для продолжения пользования сайтом."
            );
            router.push("/");
            return Promise.reject(error);
          }

          const response = await axiosInstance.post("/auth/jwt/refresh", {
            refresh: refreshToken,
          });
          store.dispatch("user/me");

          if (response.data.access) {
            const newAccessToken = response.data.access;
            TokenService.updateLocalAccessToken(newAccessToken, refreshToken);
            store.dispatch("auth/refreshToken", newAccessToken);
            // Повторный запрос
            return axiosInstance(originalConfig);
          }
        } catch (_error) {
          console.error(_error);
          store.dispatch("auth/logout");
          router.push("/");
          return Promise.reject(_error);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default setup;
