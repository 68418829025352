import api from "./api";

interface Plan {
  id: string;
}

interface OrderResponse {
  order_url: string;
}

class PaymentService {
  // Получить список тарифов
  async getPlans(): Promise<Plan[]> {
    const res = await api.get("/api/v1/plans/");
    return res.data as Plan[];
  }

  // Создание заказа
  async createOrder(plan: Plan): Promise<OrderResponse> {
    const res = await api.post("/api/v1/plan-order/", {
      plan: plan,
    });
    return res.data as OrderResponse;
  }

  // Проверка наличия подписки
  async getMemberships(): Promise<any[]> {
    const res = await api.get("/api/v1/memberships/");
    return res.data as any[];
  }
}

export default new PaymentService();
