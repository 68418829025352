<template>
  <div class="card main-info">
    <div class="card-body">
      <h5 class="card-title">Финансы</h5>
      <ul class="info-list">
        <div class="d-flex pt-3">
          <li class="col-4">
            <span class="info-title">Выручка</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
          <li class="col-4">
            <span class="info-value">Себестоимость</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
          <li class="col-4">
            <span class="info-value">Прибыль</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
        </div>
      </ul>
      <LineChart class="mt-2" :finances="finances" />
      <ul class="info-list mt-3">
        <li><span class="info-title">Финансовое состояние</span></li>
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom mt-2"
        >
          <span class="info-value success">Положительные</span
          ><span class="info-bold">15</span>
        </li>
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value danger">Требуют внимания</span
          ><span class="info-bold">2</span>
        </li>
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value decline">Отрицательные</span>
          <span class="info-bold">0</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-finances')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import LineChart from "./Diagrams/LineChart.vue";
export default {
  name: "FinancesInfo",
  components: { LineChart },
  props: {
    finances: Object,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      emitChangeTab,
    };
  },
};
</script>
