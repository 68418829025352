<template>
  <div>
    <h5 class="modal-title">Восстановить пароль</h5>
    <p>
      Письмо с дальнейшими инструкциями будет отправлено вам на указанный
      почтовый адрес.
    </p>
    <form @submit.prevent="resetPassword">
      <div class="mb-3">
        <label for="email" class="form-label">E-mail</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary">Продолжить</button>
      <button
        type="button"
        class="btn btn-link"
        @click="switchView('AuthView')"
      >
        Вспомнили пароль?
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ForgotPasswordView",
  setup(props, { emit }) {
    const email = ref("");

    const resetPassword = () => {
      // Здесь можно реализовать логику восстановления пароля
      console.log("E-mail для восстановления пароля:", email.value);
    };

    const switchView = (view) => {
      emit("switchView", view);
    };

    return {
      email,
      resetPassword,
      switchView,
    };
  },
};
</script>
