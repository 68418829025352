<template>
  <div
    class="modal fade payment-modal"
    id="tariffModal"
    tabindex="-1"
    aria-labelledby="paymentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header">
          <img src="@/assets/images/logo.svg" alt="Info Global Logo" />
        </div>
        <div class="modal-body">
          <h5>Период</h5>
          <div class="period-options mt-3">
            <div
              v-for="option in options"
              :key="option.value"
              class="option"
              :class="{ selected: selectedOption === option.value }"
              @click="selectOption(option)"
            >
              <input
                type="radio"
                :id="option.value"
                :value="option.value"
                v-model="selectedOption"
              />
              <label :for="option.value">{{ option.label }}</label>
              <span>{{ option.price }}</span>
            </div>
          </div>
          <div class="summary mt-4 p-3">
            <h6>Итого</h6>
            <p>
              Период <span class="float-end">{{ selectedOptionLabel }}</span>
            </p>
            <p>
              К оплате <span class="float-end">{{ selectedPrice }}</span>
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100"
            @click="goToPayment(selectedOption)"
          >
            Оплатить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap";

export default {
  name: "PaymentModal",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);

    const options = ref([
      { id: 1, value: 1, label: "Год", price: "2900 рублей" },
      { id: 2, value: 2, label: "Месяц", price: "290 рублей" },
    ]);

    const selectedOption = ref(1);

    const selectedOptionLabel = computed(() => {
      const option = options.value.find(
        (opt) => opt.value === selectedOption.value
      );
      return option ? option.label : "";
    });

    const selectedPrice = computed(() => {
      const option = options.value.find(
        (opt) => opt.value === selectedOption.value
      );
      return option ? option.price : "";
    });

    const selectOption = (option) => {
      selectedOption.value = option.value;
    };

    const goToPayment = (plan) => {
      console.log("plan", plan);
      if (isLoggedIn.value) {
        store.dispatch("payments/createOrder", plan).then((res) => {
          window.location.href = res.order_url;
        });
      } else {
        const loginModal = new Modal(document.getElementById("authModal"));
        loginModal.show();
      }
    };
    return {
      options,
      selectedOption,
      selectedOptionLabel,
      selectedPrice,
      selectOption,
      goToPayment,
    };
  },
};
</script>

<style scoped>
.payment-modal .modal-content {
  border-radius: 15px;
  padding: 20px;
}

.period-options .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.period-options .option.selected {
  background-color: #e0f0ff;
}

.period-options input[type="radio"] {
  display: none;
}

.period-options label {
  margin-left: 10px;
  font-weight: bold;
}

.summary {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.summary p {
  margin: 0;
  font-size: 14px;
}

.modal-footer {
  margin-top: 20px;
}
</style>
