
import { defineComponent, onMounted, computed, ref } from "vue";
import ProductCard from "../../components/Marketplace/ProductCard.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MarketplaceService from "../../services/marketplace";
import type { Favorite } from "../../services/marketplace";

export default defineComponent({
  name: "MarketplacePage",
  components: {
    ProductCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loggedIn = computed(() => store.state.auth.status.loggedIn);
    const products = ref<Favorite[]>([]);
    const isLoading = ref(false);

    onMounted(async () => {
      if (!loggedIn.value) {
        router.push("/login");
      } else {
        isLoading.value = true;
        MarketplaceService.getFavorites().then((res) => {
          products.value = res;
          isLoading.value = false;
        });
      }
    });
    return { products };
  },
});
