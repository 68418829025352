
import { defineComponent } from "vue";
import HeroSection from "../components/MainPage/HeroSection.vue";
import CompasSection from "../components/MainPage/CompasSection.vue";
import FeaturesSection from "../components/MainPage/FeaturesSection.vue";
import InformationSection from "../components/MainPage/InformationSection.vue";
import AboutSection from "../components/MainPage/AboutSection.vue";
import PricingSection from "../components/MainPage/PricingSection.vue";

export default defineComponent({
  name: "MainPage",
  components: {
    HeroSection,
    CompasSection,
    FeaturesSection,
    InformationSection,
    AboutSection,
    PricingSection,
  },
});
