<template>
  <div
    class="modal fade auth-modal"
    id="passwordModal"
    tabindex="-1"
    aria-labelledby="passwordModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Сменить пароль</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <h4>{{ text }}</h4>
        <div class="modal-body">
          <form @submit.prevent="setPassword" class="mt-4">
            <div class="mb-3">
              <label for="currentPassword" class="form-label"
                >Старый пароль</label
              >
              <input
                type="password"
                class="form-control"
                id="currentPassword"
                v-model="user.currentPassword"
                required
              />
            </div>
            <div class="mb-3">
              <label for="newPassword" class="form-label">Новый пароль</label>
              <input
                type="password"
                class="form-control"
                id="newPassword"
                v-model="user.newPassword"
                required
              />
            </div>
            <button type="submit" class="black-button mt-4">Сохранить</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import AuthService from "../../services/auth";

export default defineComponent({
  setup() {
    const user = ref({
      email: "",
      password: "",
    });
    const text = ref(null);
    const setPassword = () => {
      AuthService.setPassword(user)
        .then((response) => {
          text.value = "Ваш пароль успешно обновлен";
          return Promise.resolve(response);
        })
        .catch((error) => {
          if (
            error.response.data.email[0] ===
            "Пользователь with this Электронная почта already exists."
          ) {
            alert("Пользватель с такой почтой уже зарегестрирован");
          }
          return Promise.reject(error);
        });
    };

    return {
      user,
      setPassword,
    };
  },
});
</script>
