<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">События</h5>

      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Важные</span
          ><span class="info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Реестры ФНС</span
          ><span class="info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Долги</span
          ><span class="info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Арбитражные события</span
          ><span class="info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Банкротство</span
          ><span class="info-bold text-end">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Изменения ЕГРЮЛ</span
          ><span class="info-bold text-end">5463</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RisksInfo",
};
</script>
