import { createStore } from "vuex";
import axios from "axios";
import { auth } from "./auth"; // Путь к вашему модулю
import { companies } from "./companies"; // Путь к вашему модулю
import { user } from "./user"; // Путь к вашему модулю
import { payments } from "./payment"; // Путь к вашему модулю
import { chats } from "./chat";

export default createStore({
  modules: {
    auth,
    companies,
    user,
    payments,
    chats,
  },
  state: {
    companyData: null,
    loading: false,
    error: null,
  },
  mutations: {},
  actions: {},
  getters: {},
});
