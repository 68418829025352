<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Филиалы и представительства</h5>
      </div>
      <div class="card-body">
        <p>
          Сведения о филиалах и представительствах организации в РФ и за рубежом
          на основании данных из ЕГРЮЛ
        </p>
        <div
          class="connections-list"
          v-for="(branch, index) in branches"
          :key="index"
        >
          {{ branch.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Branches",
  props: {
    branches: Array,
  },
});
</script>
