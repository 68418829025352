import api from "./api";

class ChatRoomService {
  addRoom(data: { product: string }) {
    return api.post("/api/v1/chat-room/", data).then((res) => {
      return res.data;
    });
  }
  getAll() {
    return api.get("/api/v1/chat-room/").then((res) => {
      return res.data;
    });
  }
  getRoom(id: number) {
    return api.get("/api/v1/messages/?chat_room__in=" + id).then((res) => {
      return res.data;
    });
  }
}

export default new ChatRoomService();
