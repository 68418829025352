
import { defineComponent, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "SearchComponent",
  setup() {
    const isFocused = ref(false);
    const searchQuery = ref("");
    const filteredResults = ref<any[]>([]);
    const router = useRouter();
    const store = useStore();

    const goToCompany = (companyId: string) => {
      isFocused.value = false;
      router.push("/counter-parties/" + companyId);
    };

    const companies = computed(() => {
      if (store.state.companies.companies) {
        console.log(
          "Companies from store:",
          store.state.companies.companies[0]["Записи"]
        );
      }
      return store.state.companies.companies;
    });

    const onSearch = () => {
      isFocused.value = true;
      if (searchQuery.value) {
        store.dispatch("companies/searchCompanies", searchQuery.value);
      }
    };

    return {
      goToCompany,
      companies,
      searchQuery,
      filteredResults,
      isFocused,
      onSearch,
    };
  },
});
