import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainPage from "../views/MainPage.vue";
import CounterParties from "../views/counterparties/CounterParties.vue";
import Profile from "../views/Profile.vue";
import ProfileInfo from "../views/profile/ProfileInfo.vue";
import Subscription from "../views/profile/Subscription.vue";
import Friends from "../views/profile/Friends.vue";
import Basket from "../views/profile/Basket.vue";
import Favorites from "../views/profile/Favorites.vue";
import Orders from "../views/profile/Orders.vue";
import Products from "../views/profile/Products.vue";
import AddProduct from "../views/profile/AddProduct.vue";
// import Chat from "../views/profile/ChatPage.vue";
import Support from "../views/profile/Support.vue";
import Reviews from "../views/profile/Reviews.vue";

import Marketplace from "../views/Marketplace.vue";
import MarketplaceItem from "../views/marketplace/Item.vue";
import Company from "../views/marketplace/Company.vue";
import Messages from "@/views/profile/Messages.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MainPage",
    component: MainPage,
  },
  {
    path: "/counter-parties/:id",
    name: "CounterParties",
    component: CounterParties,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    children: [
      {
        path: "info",
        name: "ProfileInfo",
        component: ProfileInfo,
      },
      {
        path: "subscription",
        name: "Subscription",
        component: Subscription,
      },
      {
        path: "friends",
        name: "Friends",
        component: Friends,
      },
      {
        path: "basket",
        name: "Basket",
        component: Basket,
      },
      {
        path: "favorites",
        name: "Favorites",
        component: Favorites,
      },
      {
        path: "orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "products",
        name: "Products",
        component: Products,
      },
      {
        path: "products/add",
        name: "AddProduct",
        component: AddProduct,
      },
      {
        path: "products/edit/:id",
        name: "EditProduct",
        component: AddProduct,
      },
      {
        path: "messages",
        name: "Messages",
        component: Messages,
      },
      {
        path: "reviews",
        name: "Reviews",
        component: Reviews,
      },
      {
        path: "support",
        name: "Support",
        component: Support,
      },
    ],
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: Marketplace,
  },
  {
    path: "/marketplace/:id",
    name: "MarketplaceItem",
    component: MarketplaceItem,
  },
  {
    path: "/company/:id",
    name: "Company",
    component: Company,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
