<template>
  <div>
    <canvas ref="barChartCanvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "BarChart",
};
</script>
<script setup>
import { ref, onMounted, defineProps, onUnmounted } from "vue";
import { BarElement, CategoryScale, LinearScale, Title } from "chart.js";
import Chart from "chart.js/auto";

// Регистрация компонентов Chart.js
Chart.register(BarElement, CategoryScale, LinearScale, Title);

// Получение пропсов
const props = defineProps({
  chartData: {
    type: Object,
    required: true,
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});
// Получение ссылки на элемент canvas и создание графика после монтирования компонента
const barChartCanvas = ref(null);
let chartInstance = null;
const initChart = () => {
  if (chartInstance) {
    chartInstance.destroy();
  }
  const ctx = barChartCanvas.value?.getContext("2d");
  if (ctx) {
    chartInstance = new Chart(ctx, {
      type: "bar",
      data: props.chartData,
      options: props.chartOptions,
    });
  } else {
    console.error("Canvas context is not available.");
  }
};
// Функция для проверки видимости элемента
const checkVisibility = (entries) => {
  if (entries[0]?.isIntersecting) {
    initChart();
  }
};
onMounted(() => {
  const observer = new IntersectionObserver(checkVisibility, {
    threshold: 0.1,
  });
  observer.observe(barChartCanvas.value);

  onUnmounted(() => {
    if (observer) {
      if (barChartCanvas.value && barChartCanvas.value instanceof Element) {
        observer.unobserve(barChartCanvas.value);
      }
    }
    if (chartInstance) {
      chartInstance.destroy();
    }
  });
});
</script>

<style scoped>
canvas {
  width: 100% !important;
  height: 320px !important;
}
</style>
