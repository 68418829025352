<template>
  <div class="profile cart pb-4">
    <div class="card profile-info">
      <div class="card-body">
        <h2>Отзывы</h2>
        <div class="reviews-block">
          <div class="review-stars row flex-wrap gy-2">
            <div
              v-for="review in comments"
              :key="review.id"
              class="reviews-block__box"
            >
              <img
                :src="
                  review.product.images.at(0)?.image ||
                  require('@/assets/images/logo_info.svg')
                "
                alt="Product Image"
              />
              <div class="ms-4">
                <p>Оценка</p>
                <div class="rating">
                  <span
                    v-for="(star, index) in review.stars"
                    :key="index"
                    class="stars me-2"
                    >★</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="reviews-list">
            <div
              class="review mt-4"
              v-for="review in comments"
              :key="review.id"
            >
              <div class="review-header d-flex">
                <img
                  :src="
                    review.product.images.at(0)?.image ||
                    require('@/assets/images/logo_info.svg')
                  "
                  alt="Product"
                  class="product-img"
                />
                <div class="review-info ms-4">
                  <div class="rating">
                    <span
                      v-for="(star, index) in review.stars"
                      :key="index"
                      class="stars me-2"
                      >★</span
                    >
                    <p class="review-date ms-2">
                      {{ formatDate(review.time_create) }}
                    </p>
                  </div>
                  <p class="review-name">{{ review.product.name }}</p>
                </div>
              </div>
              <div class="review-body d-flex">
                <img src="@/assets/images/icons/comment.svg" />
                <p class="ms-4">{{ review.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import MarketplaceService from "@/services/marketplace";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "Reviews",
  setup() {
    const store = useStore();
    const comments = ref([]);

    onMounted(async () => {
      const user = await store.dispatch("user/me");
      MarketplaceService.comments(user.id).then((res) => {
        console.log("res", res);
        comments.value = res;
        console.log(comments.value);
      });
    });
    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };
    return {
      comments,
      formatDate,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/profile/reviews.scss";
</style>
