<template>
  <div class="product d-flex my-4">
    <input
      class="me-4"
      type="checkbox"
      :id="'product-' + product.id"
      :value="product.id"
      v-model="checked"
      @change="emitSelection"
    />
    <div class="product__image">
      <img
        :src="
          product.images.at(0)?.image ||
          require('@/assets/images/logo_info.svg')
        "
        alt=""
      />
    </div>
    <div class="product__content col-6 ms-4">
      <h3>{{ product.name }}</h3>
      <p>{{ product.description }}</p>
    </div>
    <div class="product__counts d-flex align-items-center ms-4">
      <Counter
        :small="true"
        :countInc="
          () => {
            countToAdd += 1;
            addToCart();
          }
        "
        :countDec="
          () => {
            if (countToAdd !== 1) {
              countToAdd -= 1;
              addToCart();
            }
          }
        "
        :count="countToAdd"
      />
      <h2 class="ms-4">{{ product.sale_price }} ₽</h2>
    </div>
  </div>
</template>

<script>
import marketplaceService from "@/services/marketplace";
import Counter from "@/components/Profile/Counter.vue";
import { ref, defineComponent, watch } from "vue";
import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";

export default defineComponent({
  name: "ProductCard",
  props: {
    product: Object,
    id: Number,
    reloadCart: Function,
    quantity: Number,
    selectedProducts: Array,
  },
  components: {
    Counter,
  },
  setup(props, { emit }) {
    const store = useStore();
    const likedProduct = ref(props.product.is_liked);
    const countToAdd = ref(props.quantity);
    const checked = ref(props.selectedProducts.includes(props.product.id));

    const clickLike = async () => {
      if (!likedProduct.value) {
        await marketplaceService.addToFavorite(props.product.id);
        likedProduct.value = true;
      } else {
        await marketplaceService.removeFromFavorite(props.product.id);
        likedProduct.value = false;
      }
    };
    const emitSelection = () => {
      const updatedSelection = checked.value
        ? [...props.selectedProducts, props.product.id]
        : props.selectedProducts.filter((id) => id !== props.product.id);

      emit("update:selectedProducts", updatedSelection);
    };
    const addToCart = useDebounceFn(async () => {
      const value = countToAdd.value;

      if (value) {
        await marketplaceService.addToBasket(
          props.product.id,
          value - props.quantity
        );
        await store.dispatch("user/getCartItems");
        props.reloadCart();
      }
    }, 1000);

    const removeItem = async () => {
      await marketplaceService.delete(props.id);
      props.reloadCart();
    };

    watch(
      () => props.selectedProducts,
      (newSelection) => {
        checked.value = newSelection.includes(props.product.id);
      }
    );
    return {
      emitSelection,
      removeItem,
      addToCart,
      clickLike,
      likedProduct,
      countToAdd,
      checked,
    };
  },
});
</script>
