<template>
  <div class="profile cart pb-4">
    <div class="card profile-info">
      <div class="card-body">
        <h2>Поддержка</h2>
        <div class="products-container mt-2">
          <button class="black-button mt-2" @click="openSupportModal">
            Создать обращение
          </button>

          <SupportModal v-if="isSupportModalOpen" @close="closeSupportModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import SupportModal from "@/components/Modals/SupportModal.vue";

export default defineComponent({
  name: "Support",
  components: {
    SupportModal,
  },
  setup() {
    const isSupportModalOpen = ref(false);

    const openSupportModal = () => {
      isSupportModalOpen.value = true;
    };

    const closeSupportModal = () => {
      isSupportModalOpen.value = false;
    };

    return {
      isSupportModalOpen,
      openSupportModal,
      closeSupportModal,
    };
  },
});
</script>
