
import { defineComponent, ref } from "vue";
import MarketplaceService from "@/services/marketplace";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const productAdded = ref(false);
    const likedProduct = ref(props.product.is_liked);

    const clickLike = async () => {
      if (!likedProduct.value) {
        await MarketplaceService.addToFavorite(props.product.id);
        likedProduct.value = true;
      } else {
        await MarketplaceService.removeFromFavorite(props.product.id);
        likedProduct.value = false;
      }
    };

    const addToCart = async () => {
      await MarketplaceService.addToBasket(props.product.id, 1);
      store.dispatch("getCartItems");

      productAdded.value = true;
      setTimeout(() => {
        productAdded.value = false;
      }, 1000);
    };

    const goToItem = () => {
      router.push({
        name: "MarketplaceItem",
        params: { id: props.product.id },
      });
    };

    return {
      goToItem,
      clickLike,
      addToCart,
      likedProduct,
      productAdded,
      router,
    };
  },
});
