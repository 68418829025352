
import { defineComponent, computed } from "vue";
import MainPageHeader from "./components/Header.vue";
import MainPageFooter from "./components/Footer.vue";
import SearchHeader from "./components/SearchHeader.vue";
import SmallFooter from "./components/SmallFooter.vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    MainPageHeader,
    MainPageFooter,
  },
  setup() {
    const route = useRoute();

    const currentHeaderComponent = computed(() => {
      if (route.name === "MainPage") {
        return MainPageHeader;
      } else {
        return SearchHeader;
      }
    });

    const currentFooterComponent = computed(() => {
      if (route.name === "MainPage") {
        return MainPageFooter;
      } else {
        return SmallFooter;
      }
    });
    return {
      currentHeaderComponent,
      currentFooterComponent,
    };
  },
});
