<template>
  <div>
    <h4 v-if="order">№ {{ order.id }} от {{ formatDate(order.create_at) }}</h4>
    <div class="product-card d-flex flex-md-row flex-column align-items-start">
      <img
        @click="goToItem()"
        class="product-image"
        :src="
          product?.images?.length
            ? product.images[0]?.image
            : require('@/assets/images/logo_info.svg')
        "
        alt=""
      />
      <div class="product-info ms-md-4 col-md-4 col-12 mt-md-0 mt-2">
        <h3>{{ product.name }}</h3>
        <p>{{ product.price }} ₽</p>
      </div>
      <template v-if="!order">
        <button
          @click="clickLike"
          class="like-button ms-4"
          :class="{ liked: likedProduct === true }"
        >
          <svg
            width="35"
            height="30"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18054 13.5549L7.17983 13.5542C5.11017 11.6365 3.43202 10.0786 2.26545 8.62023C1.10468 7.16915 0.5 5.8759 0.5 4.49591C0.5 2.23655 2.22166 0.5 4.4 0.5C5.6368 0.5 6.83521 1.09095 7.61714 2.02186L8 2.47767L8.38286 2.02186C9.16479 1.09095 10.3632 0.5 11.6 0.5C13.7783 0.5 15.5 2.23655 15.5 4.49591C15.5 5.8759 14.8953 7.16915 13.7346 8.62023C12.568 10.0786 10.8898 11.6365 8.82017 13.5542L8.81946 13.5549L8 14.3171L7.18054 13.5549Z"
              stroke="#313132"
            />
          </svg>
        </button>
        <button class="ms-auto btn btn-link" @click="editProduct()">
          Редактировать
        </button>
      </template>
      <template v-else>
        <ul class="ms-auto">
          <li>
            <span>Статус</span>
            {{ statusLabel(order.status) }}
          </li>
          <li><span>Оплата</span> {{ paymentStatusLabel(order.paid) }}</li>
        </ul>

        <button
          class="btn btn-primary"
          @click.prevent="openReviewModal"
          v-if="order.status === 'completed'"
        >
          Оценить товар
        </button>
      </template>
    </div>
  </div>
  <ReviewModal
    v-if="isReviewModalOpen"
    @close="closeReviewModal"
    :id="product.id"
  />
</template>

<script>
import { defineComponent, ref } from "vue";
import MarketplaceService from "../../services/marketplace";
import { useRouter } from "vue-router";
import moment from "moment";
import ReviewModal from "@/components/Modals/ReviewModal.vue";

export default defineComponent({
  name: "NewProductCard",
  components: {
    ReviewModal,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    order: Object,
  },
  setup(props) {
    const router = useRouter();
    const productAdded = ref(false);
    const likedProduct = ref(props.product?.is_liked);
    const isReviewModalOpen = ref(false);

    const openReviewModal = () => {
      isReviewModalOpen.value = true;
    };

    const closeReviewModal = () => {
      isReviewModalOpen.value = false;
      console.log("isReviewModalOpen", isReviewModalOpen);
    };

    const clickLike = async () => {
      if (!likedProduct.value) {
        await MarketplaceService.addToFavorite(props.product.id);
        likedProduct.value = true;
      } else {
        await MarketplaceService.removeFromFavorite(props.product.id);
        likedProduct.value = false;
      }
    };

    const goToItem = () => {
      router.push({
        name: "MarketplaceItem",
        params: { id: props.product.id },
      });
    };

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    const statusLabel = (status) => {
      switch (status) {
        case "pending":
          return "Ожидается";
        case "in_progress":
          return "В пути";
        case "not_paid":
          return "Не оплачен";
        case "completed":
          return "Выполнен";
        default:
          return "";
      }
    };

    const paymentStatusLabel = (status) => {
      switch (status) {
        case true:
          return "Оплачено";
        case false:
          return "Не оплачено";
      }
    };

    const editProduct = () => {
      router.push(`/profile/products/edit/${props.product.id}`);
    };

    return {
      goToItem,
      clickLike,
      formatDate,
      statusLabel,
      paymentStatusLabel,
      closeReviewModal,
      openReviewModal,
      editProduct,
      isReviewModalOpen,
      likedProduct,
      productAdded,
      router,
    };
  },
});
</script>

<style scoped lang="scss">
p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.like-button {
  padding: 0;
  border: none;
  cursor: pointer;
  svg {
    transition: all 0.2s;
    fill: #fff;
    path {
      stroke: #d44233;
    }
  }
  background-color: inherit;

  &.liked {
    svg {
      fill: #d44233;
      path {
        stroke: #d44233;
      }
    }
  }
}
.product-card {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.product-image {
  min-width: 250px;
  height: 150px;
  object-fit: cover;
  border-radius: 30px;
}

.product-info {
  h3 {
    color: #0066ce;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 23.87px;
  }
}

.rating {
  color: #ffc107;
  margin-bottom: 10px;
}
ul {
  list-style: none;
  li {
    font-size: 12px;
    color: #676767;
    span {
      max-width: 30%;
      color: #a3a3a3;
      font-size: 15px;
    }
  }
}
</style>
