<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Филиалы и представительства</h5>
      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Всего</span
          ><span class="info-bold text-end col-3">{{ amount }}</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-branches')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BranchesInfo",
  props: {
    amount: Number,
  },
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      emitChangeTab,
    };
  },
};
</script>
