<template>
  <div>
    <canvas ref="polarChartCanvas"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import {
  Chart,
  PolarAreaController,
  RadialLinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Регистрация компонентов Chart.js
Chart.register(
  PolarAreaController,
  RadialLinearScale,
  Tooltip,
  Legend,
  ArcElement
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        textAlign: "start",
        usePointStyle: true, // Используем стиль точки
        pointStyle: "circle", // Устанавливаем стиль иконки как круглый
        color: "#3E3E3E",
        font: {
          size: 15, // Размер шрифта меток легенды
        },
        padding: 20, // Отступ между элементами легенды
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => `${context.label}: ${context.raw}`,
      },
    },
  },
  scales: {
    r: {
      ticks: {
        display: false, // Убирает метки на шкале
        callback: function (value) {
          return value.toLocaleString("en-US", {
            minimumFractionDigits: Math.min(Math.max(0, 100), 20),
          });
        },
      },
    },
  },
};

// Пропсы для графика
const props = defineProps({
  chartData: {
    type: Object,
    required: true,
  },
  chartOptions: {
    type: Object,
    default: () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          color: "#3E3E3E",
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.label}: ${context.raw}`,
          },
        },
      },
    }),
  },
});

const polarChartCanvas = ref(null);
let chartInstance = null;

const initChart = () => {
  if (chartInstance) {
    chartInstance.destroy();
  }
  const ctx = polarChartCanvas.value?.getContext("2d");
  if (ctx) {
    chartInstance = new Chart(ctx, {
      type: "polarArea",
      data: props.chartData,
      options: options,
    });
  } else {
    console.error("Canvas context is not available.");
  }
};

onMounted(() => {
  initChart();
});
</script>

<style scoped>
canvas {
  width: 100% !important;
  height: 500px !important; /* Убедитесь, что высота установлена корректно */
}
</style>
