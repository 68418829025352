<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Лицензии</h5>
      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Всего</span
          ><span class="info-bold text-end col-3">{{ amount }}</span>
        </li>
        <!-- <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">ЕГРЮЛ</span
          ><span class="info-bold text-end col-3">390 млр. р.</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Росалкогольрегулирование </span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Росздравнадзор</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Роскомнадзор (Связь)</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">Рособрнадзор</span
          ><span class="info-bold text-end col-3">5463</span>
        </li>
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">ФСТЭК (ТЗКИ)</span
          ><span class="info-bold text-end col-3">5463</span>
        </li> -->
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-licenses')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LicensesInfo",
  props: {
    amount: Number,
  },
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      emitChangeTab,
    };
  },
};
</script>
