<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Реестры ФНС</h5>
      <span>Арбитражные дела с участием</span>
      <ul class="info-list mt-2">
        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Недобросовестные поставщики</span
          ><span
            class="col-2 info-bold align-self-start"
            style="font-size: 15px; width: 25%"
            >Да</span
          >
        </li>
        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Дисквалифицированные лица</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Состоит</span
          >
        </li>

        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Массовый руководитель</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Не состоит</span
          >
        </li>
        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Массовый учредитель</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Не состоит</span
          >
        </li>

        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Налоговая задолженность</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Не состоит</span
          >
        </li>
        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Недостоверность руководителя</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Не состоит</span
          >
        </li>
        <li class="d-flex flex-row align-items-end">
          <span class="info-value">Недостоверность адреса</span
          ><span class="col-2 info-bold" style="font-size: 15px; width: 25%"
            >Нет</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistriesInfo",
};
</script>
