<template>
  <div class="counter d-flex align-items-center" :class="{ small: small }">
    <button class="counter__decrement me-3" @click="countDec">-</button>
    <div contenteditable="true" type="number" @input="onInput">
      {{ count }}
    </div>
    <button class="counter__increment ms-3" @click="countInc">+</button>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  count: Number,
  countInc: Function,
  small: Boolean,
  countDec: Function,
  addToCart: Function,
});

const onInput = (e) => {
  const sanitizedValue = e.target.innerText.replace(/[^0-9]/g, "");
  e.target.innerText = sanitizedValue;

  if (sanitizedValue) {
    props.addToCart(Number(sanitizedValue));
  }
};
</script>
