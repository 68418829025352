<template>
  <div class="row summary">
    <div class="col-md-6 col-lg-5 col-12 mb-4 first-block">
      <MainInfo class="mb-4" :company="company" />
      <FinancesInfo
        v-if="isLoggedIn"
        class="mb-4"
        :finances="finances"
        @change-tab="handleTabChange"
      />
      <DebtsInfo class="mb-4" @change-tab="handleTabChange" />
      <LicensesInfo
        v-if="isLoggedIn"
        class="mb-4"
        :amount="company?.licenses.length || 0"
        @change-tab="handleTabChange"
      />
      <BranchesInfo
        v-if="isLoggedIn"
        :amount="company?.division[0]?.filials.length || 0"
        @change-tab="handleTabChange"
      />
    </div>
    <div class="col-md-6 col-lg-3 col-12 mb-4 second-block">
      <ContactInfo class="mb-4" :contacts="company?.contacts" />
      <ConnectionsInfo class="mb-4" @change-tab="handleTabChange" />
      <DocumentsInfo class="mb-4" />
      <FoundersInfo
        v-if="isLoggedIn"
        class="mb-4"
        :founders="company?.founders[0]?.PP"
      />
      <EventsInfo class="mb-4" />
      <CheckingsInfo
        v-if="isLoggedIn"
        class="mb-4"
        :inspections="inspections"
        @change-tab="handleTabChange"
      />
      <CompetitorsInfo />
    </div>
    <div class="col-md-6 col-lg-4 col-12 mb-4 third-block">
      <AnalyzeInfo
        class="mb-4"
        :company="company"
        @change-tab="handleTabChange"
      />
      <ArbitrationInfo class="mb-4" @change-tab="handleTabChange" />
      <RegistriesInfo class="mb-4" />
      <RisksInfo class="mb-4" />
      <PurchasesInfo class="mb-4" />
      <ShortInfo />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MainInfo from "./cards/MainInfo.vue";
import ContactInfo from "./cards/ContactInfo.vue";
import AnalyzeInfo from "./cards/AnalyzeInfo.vue";
import ConnectionsInfo from "./cards/ConnectionsInfo.vue";
import DocumentsInfo from "./cards/DocumentsInfo.vue";
import ArbitrationInfo from "./cards/ArbitrationInfo.vue";
import RegistriesInfo from "./cards/RegistriesInfo.vue";
import RisksInfo from "./cards/RisksInfo.vue";
import FoundersInfo from "./cards/FoundersInfo.vue";
import EventsInfo from "./cards/EventsInfo.vue";
import CheckingsInfo from "./cards/CheckingsInfo.vue";
import CompetitorsInfo from "./cards/CompetitorsInfo.vue";
import PurchasesInfo from "./cards/PurchasesInfo.vue";
import ShortInfo from "./cards/ShortInfo.vue";
import FinancesInfo from "./cards/FinancesInfo.vue";
import DebtsInfo from "./cards/DebtsInfo.vue";
import LicensesInfo from "./cards/LicensesInfo.vue";
import BranchesInfo from "./cards/BranchesInfo.vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    company: Object,
    finances: Object,
    inspections: Array,
    activeTab: String,
  },
  setup(_, { emit }) {
    const store = useStore();
    const isLoggedIn = store.state.auth.status.loggedIn;

    const handleTabChange = (newTab) => {
      emit("change-tab", newTab);
    };

    return {
      handleTabChange,
      isLoading: store.getters.isLoading,
      error: store.getters.getError,
      isLoggedIn,
    };
  },
  components: {
    MainInfo,
    ContactInfo,
    AnalyzeInfo,
    ConnectionsInfo,
    DocumentsInfo,
    ArbitrationInfo,
    RegistriesInfo,
    RisksInfo,
    FoundersInfo,
    EventsInfo,
    CheckingsInfo,
    CompetitorsInfo,
    PurchasesInfo,
    ShortInfo,
    FinancesInfo,
    DebtsInfo,
    LicensesInfo,
    BranchesInfo,
  },
});
</script>
