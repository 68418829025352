<template>
  <div class="cart profile addProduct" v-if="productCategories.length">
    <div class="card">
      <div class="card-body">
        <form class="form row">
          <div class="col-6">
            <div class="input">
              <label for="name">Название продукта</label>
              <input
                v-model="formData.name"
                type="text"
                class="form-control mt-2"
                id="name"
                placeholder=""
                required
              />
            </div>
            <div class="input mt-3">
              <label for="description">Описание</label>
              <textarea
                v-model="formData.description"
                type="text"
                class="form-control mt-2"
                rows="5"
                id="description"
                placeholder=""
                required
              />
            </div>
            <div class="input images mt-3">
              <label for="inputGroupFile01">Фото товара</label>
              <input
                ref="fileInput"
                @change="onFileChange($event)"
                type="file"
                class="form-control d-none"
                style="height: fit-content"
                id="inputGroupFile01"
                multiple
                :disabled="productImages?.length >= 8"
              />
              <div
                class="selectImage mt-2"
                v-if="!productImages?.length"
                @click="triggerFileUpload"
              >
                <img src="@/assets/images/icons/image.png" />
              </div>
              <div class="imagesa" v-if="productImages?.length">
                <div
                  class="a"
                  v-for="(item, index) in productImages"
                  :key="index"
                >
                  <img :src="item" width="100" height="100" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-row">
              <label for="flexSwitchCheckDefault">В наличии</label>
              <input
                v-model="formData.in_stock"
                type="checkbox"
                class="ms-2"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder=""
              />
            </div>
            <div class="input-row mt-3">
              <label for="flexSwitchCheckDefault">Цена включает налоги</label>
              <input
                v-model="formData.price_with_taxes"
                type="checkbox"
                class="ms-2"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder=""
              />
            </div>
            <div class="input tags mt-3">
              <label for="tags">Теги</label>
              <select class="form-select mt-2" v-model="formTags" multiple>
                <option v-for="(tag, index) in productTags" :key="index">
                  {{ tag }}
                </option>
              </select>
            </div>
            <div class="input mt-3">
              <label for="floatingSelect">Категория</label>
              <select
                v-model="formData.category"
                class="form-select mt-2"
                id="floatingSelect"
              >
                <option
                  v-for="(item, index) in productCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="input mt-3">
              <label for="floatingSelect">Компания</label>
              <select
                v-model="formData.company"
                class="form-select mt-2"
                id="floatingSelect"
              >
                <option
                  v-for="(item, index) in productCompanies"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name_full }}
                </option>
              </select>
            </div>

            <div class="input mt-3">
              <label for="code">Код продукта</label>
              <input
                v-model="formData.product_code"
                type="text"
                class="form-control mt-2"
                id="code"
                placeholder=""
              />
            </div>
            <div class="input mt-3">
              <label for="sku">SKU продукта</label>
              <input
                v-model="formData.sku_article"
                type="text"
                class="form-control mt-2"
                id="sku"
                placeholder=""
                required
              />
            </div>
            <div class="input mt-3">
              <label for="price">Стоимость</label>
              <input
                v-model="formData.price"
                type="number"
                class="form-control mt-2"
                id="price"
                placeholder=""
                required
              />
            </div>
            <div class="input mt-3">
              <label for="salePrice">Цена со скидкой</label>
              <input
                v-model="formData.sale_price"
                type="number"
                class="form-control mt-2"
                id="salePrice"
                placeholder=""
                required
              />
            </div>
            <button class="black-button mt-4" @click.prevent="addProduct">
              Разместить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import MarketplaceService from "@/services/marketplace";
import CompanyService from "@/services/company";
import productCategoriesService from "@/services/products";

const router = useRouter();
const store = useStore();
const loggedIn = computed(() => store.state.auth.status.loggedIn);
const currentUser = ref({});
const productCategories = ref([]);
const productCompanies = ref([]);
const productTags = ref([]);
const productTagsWithIds = ref([]);
const fileInput = ref(null);
const route = useRoute();

const formData = ref({});
const formImages = ref([]);
const formTags = ref([]);
const productImages = ref([]);

onMounted(async () => {
  if (!loggedIn.value) {
    router.push("/login");
  } else {
    const user = await store.dispatch("user/me");
    currentUser.value = user;
    if (!currentUser.value.membership_exists) {
      router.push("/plans");
    }

    CompanyService.allUser(user.id).then((res) => {
      productCompanies.value = res.result;
    });

    productCategoriesService.tags().then((res) => {
      productTags.value = res.map((item) => item.name);
      productTagsWithIds.value = res;
    });

    productCategoriesService.all().then((res) => {
      productCategories.value = res;
    });
    if (route.params.id) {
      MarketplaceService.show(route.params.id).then((res) => {
        formData.value.name = res.name;
        formData.value.company = res.company;
        formData.value.description = res.description;
        formData.value.category = res.category;
        formData.value.price = res.price;
        formData.value.sale_price = res.sale_price;
        formData.value.sku_article = res.sku_article;
        formData.value.product_code = res.product_code;
      });
    }
  }
});

const triggerFileUpload = () => {
  fileInput.value.click();
};
const onFileChange = (event) => {
  if (event.target.files && event.target.files[0]) {
    const images = event.target.files;
    Array.from(images).forEach((item) => {
      productImages.value.push(URL.createObjectURL(item));
      formImages.value.push(item);
    });
  }
};

const addProduct = () => {
  const entries = Object.entries(formData.value);
  const newProduct = new FormData();
  entries.forEach(([key, value]) => {
    newProduct.append(key, value);
  });
  formTags.value.map((val) => {
    newProduct.append(
      "tags",
      productTagsWithIds.value.filter((item) => item.name === val)[0].id
    );
  });
  formImages.value.forEach((item) => {
    newProduct.append("images", item);
  });

  if (
    formData.value.name &&
    formData.value.company &&
    formData.value.description &&
    formData.value.category &&
    formData.value.price &&
    formData.value.sku_article &&
    formImages.value.length >= 1
  ) {
    if (route.params.id) {
      MarketplaceService.update(route.params.id, newProduct);
    } else {
      MarketplaceService.add(newProduct);
      router.go("/");
    }
  }
};
</script>

<style scoped lang="scss">
.selectImage {
  background-color: #f7f7f7;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}
</style>
