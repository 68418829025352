import { ActionContext, Module } from "vuex";
import PaymentService from "../services/payment";

// Define the state interface
interface PaymentState {
  plans: any[] | null;
  order: any | null;
  memberships: any[] | null;
}

export const payments: Module<PaymentState, any> = {
  namespaced: true,

  state: (): PaymentState => ({
    plans: null,
    order: null,
    memberships: null,
  }),

  actions: {
    async getPlans({ commit }: ActionContext<PaymentState, any>) {
      try {
        const res = await PaymentService.getPlans();
        commit("getPlansSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("getPlansFailure");
        return Promise.reject(error);
      }
    },

    async createOrder({ commit }: ActionContext<PaymentState, any>, plan: any) {
      console.log("plan store", plan);
      try {
        const res = await PaymentService.createOrder(plan);
        commit("createOrderSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("createOrderFailure");
        return Promise.reject(error);
      }
    },

    async getMemberships({ commit }: ActionContext<PaymentState, any>) {
      try {
        const res = await PaymentService.getMemberships();
        commit("getMembershipsSuccess", res);
        return Promise.resolve(res);
      } catch (error) {
        commit("getMembershipsFailure");
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    getPlansSuccess(state, plans: any[]) {
      state.plans = plans;
    },
    getPlansFailure(state) {
      state.plans = null;
    },
    createOrderSuccess(state, order: any) {
      state.order = order;
    },
    createOrderFailure(state) {
      state.order = null;
    },
    getMembershipsSuccess(state, memberships: any[]) {
      state.memberships = memberships;
    },
    getMembershipsFailure(state) {
      state.memberships = null;
    },
  },
};
