import api from "./api";
import { AxiosResponse } from "axios";

export interface Cart {
  total_sum: number;
  baskets: BasketItem[];
}

export interface Product {
  id: number;
  name: string;
  quantity: number;
  result: [];
  baskets: BasketItem[];
  price: number;
  rating: number;
}

interface Comment {
  id: string;
  content: string;
  stars: number;
}

export interface BasketItem {
  id: number;
  product: Product;
  quantity: number;
  total_sum: number;
}

interface Order {
  id: string;
}

interface Post {
  id: string;
  title: string;
  content: string;
}

export interface Favorite {
  id: number;
  product: Product;
}

interface UserInfoForm {
  postal_code: string;
  address: string;
  phone_number: string;
}

class MarketplaceService {
  add(data: FormData): Promise<Product> {
    return api
      .post("/api/v1/products/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: AxiosResponse<Product>) => {
        return res.data;
      });
  }

  update(id: string, data: FormData): Promise<Product> {
    return api
      .put(`/api/v1/products/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: AxiosResponse<Product>) => {
        return res.data;
      });
  }

  sendComment(
    rating: number,
    content: string,
    id: string,
    parent: string | null
  ): Promise<Comment> {
    return api
      .post(`/api/v1/products/${id}/add-comment/`, {
        content,
        stars: rating,
        parent,
      })
      .then((res: AxiosResponse<Comment>) => {
        return res.data;
      });
  }

  delete(id: string): Promise<void> {
    return api
      .delete(`/api/v1/basket/${id}/`)
      .then((res: AxiosResponse<void>) => {
        return res.data;
      });
  }

  all(page: number): Promise<Product[]> {
    return api
      .get(`/api/v1/products/?page=${page}`)
      .then((res: AxiosResponse) => {
        return res.data.result;
      });
  }

  companyRepresentative(id: string, page?: number): Promise<Product> {
    const path = page
      ? `/api/v1/products/?company__representative__in=${id}&page=${page}`
      : `/api/v1/products/?company__representative__in=${id}`;
    return api.get(path).then((res: AxiosResponse<Product>) => {
      return res.data;
    });
  }

  addToFavorite(id: string): Promise<Favorite> {
    return api
      .post(`/api/v1/products/${id}/add-to-favorites/`)
      .then((res: AxiosResponse<Favorite>) => {
        return res.data;
      });
  }

  removeFromFavorite(id: string): Promise<void> {
    return api
      .post(`/api/v1/products/${id}/del-from-favorites/`)
      .then((res: AxiosResponse<void>) => {
        return res.data;
      });
  }

  show(id: string): Promise<Product> {
    return api
      .get(`/api/v1/products/${id}/`)
      .then((res: AxiosResponse<Product>) => {
        return res.data;
      });
  }

  addToBasket(id: number, quantity: number): Promise<BasketItem> {
    return api
      .post(`/api/v1/products/${id}/add-to-basket/?quantity=${quantity}`)
      .then((res: AxiosResponse<BasketItem>) => {
        return res.data;
      });
  }

  comments(id: string): Promise<any> {
    return api
      .get(`/api/v1/comments/?user__in=${id}`)
      .then((res: AxiosResponse<any>) => {
        return res.data;
      });
  }

  getBuyLink(form: UserInfoForm): Promise<any> {
    return api
      .post(`/api/v1/user-info/`, {
        ...form,
        phone_number: form.phone_number.replace(/[^0-9]/g, ""),
      })
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }

  getCart(): Promise<Cart> {
    return api.get("/api/v1/basket/").then((res: AxiosResponse<Cart>) => {
      return res.data;
    });
  }

  getFavorites(): Promise<Favorite[]> {
    return api
      .get(`/api/v1/favorites/`)
      .then((res: AxiosResponse<Favorite[]>) => {
        return res.data;
      });
  }

  getPost(id: string): Promise<Post> {
    return api.get(`/api/v1/news/${id}/`).then((res: AxiosResponse<Post>) => {
      return res.data;
    });
  }

  getSellerOrders(): Promise<Order[]> {
    return api
      .get(`/api/v1/seller-order/`)
      .then((res: AxiosResponse<Order[]>) => {
        return res.data;
      });
  }

  getMyOrders(): Promise<Order[]> {
    return api
      .get(`/api/v1/products-order/`)
      .then((res: AxiosResponse<Order[]>) => {
        return res.data;
      });
  }
  async productsSearch(query: string): Promise<Favorite[]> {
    const res = await api.get(
      `/api/v1/products/?company__region__name__in=${query}`
    );
    return res.data;
  }
}

export default new MarketplaceService();
