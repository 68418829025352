<template>
  <div class="connections mb-4 pb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Товарные знаки</h5>
      </div>
      <div class="card-body" v-if="isLoggedIn">
        <!-- Фильтры поиска -->
        <div class="d-flex">
          <div class="filter-container d-flex align-items-center my-4">
            <label for="type-filter">Поиск</label>
            <input
              type="text"
              class="form-control ms-4"
              placeholder="Номер гос. регистрации"
              v-model="searchQuery"
            />
          </div>
        </div>

        <!-- Список товарных знаков -->
        <div
          v-for="trademark in filteredTrademarks"
          :key="trademark.trademark_id"
          class="trademark-card mb-3"
        >
          <div class="d-flex">
            <!-- Логотип товарного знака -->
            <img
              :src="trademark.logoUrl"
              alt="Trademark Logo"
              class="trademark-logo"
            />

            <!-- Информация о товарном знаке -->
            <div class="trademark-info ms-3">
              <ul class="info-list mt-4 ms-4">
                <li>
                  <span class="info-title"
                    >Номер государственной регистрации:</span
                  >
                </li>
                <li>
                  <span class="info-value">{{ trademark.trademark_id }}</span>
                </li>
                <li class="mt-2"><span class="info-title">Статус:</span></li>
                <li>
                  <span class="info-value">{{ trademark.status }}</span>
                </li>
                <li>
                  <span class="info-title">Код страны правообладателя:</span>
                </li>
                <li>
                  <span class="info-value">{{
                    trademark.registrationDate
                  }}</span>
                </li>
                <li class="mt-2">
                  <span class="info-title"
                    >Дата государственной регистрациии:</span
                  >
                </li>
                <li>
                  <span class="info-value">{{
                    trademark.registration_date
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="card mt-4">
            <div
              class="accordion accordion-flush"
              :id="'trademarkAccordion' + trademark.id"
            >
              <div
                class="accordion-item"
                v-for="section in trademark.sections"
                :key="section.id"
              >
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + trademark.id + section.id"
                  >
                    {{ section.title }}
                  </button>
                </h2>
                <div
                  :id="'collapse' + trademark.id + section.id"
                  class="accordion-collapse collapse"
                  :data-bs-parent="'#trademarkAccordion' + trademark.id"
                >
                  <div class="accordion-body">
                    {{ section.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Trademarks",
  props: {
    trademarks: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const searchQuery = ref("");
    const selectedType = ref("Все"); // Задаем значение по умолчанию
    const store = useStore();
    const isLoggedIn = store.state.auth.status.loggedIn;
    const filteredTrademarks = ref([]);

    watch(
      () => searchQuery.value,
      (newValue, oldValue) => {
        filterTrademarks();
      }
    );
    const filterTrademarks = () => {
      filteredTrademarks.value = props.trademarks?.filter((trademark) => {
        // Фильтрация по поисковому запросу
        const matchesSearch = searchQuery.value
          ? trademark.trademark_id
              .toString()
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
          : true;
        return matchesSearch;
      });
    };

    filterTrademarks();

    return {
      isLoggedIn,
      searchQuery,
      selectedType,
      filteredTrademarks,
    };
  },
});
</script>

<style scoped>
.trademark-logo {
  object-fit: cover;
  border-radius: 30px;
}
ul {
  column-count: 2;
  column-gap: 5rem;
  line-height: 3rem;
}
</style>
