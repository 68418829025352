<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Анализ надежности</h5>
      </div>
      <div class="card-body">
        <ul class="info-list d-flex col-9">
          <li class="d-flex flex-row col-3 align-items-center">
            <span class="info-title">Все факторы</span>
            <span class="info-value">34</span>
          </li>
          <li class="d-flex flex-row col-3 align-items-center ms">
            <span class="info-value d-flex align-items-center">
              <div class="circle success me-3"></div>
              Положительные</span
            >
            <span class="text-end">5</span>
          </li>
          <li class="d-flex flex-row col-3 align-items-center ms-4">
            <span class="info-value d-flex align-items-center">
              <div class="circle danger me-3"></div>
              Требуют внимания</span
            >
            <span class="text-end">5</span>
          </li>
          <li class="d-flex flex-row col-3 align-items-center ms-4">
            <span class="info-value d-flex align-items-center">
              <div class="circle decline me-3"></div>
              Отрицательные</span
            >
            <span class="text-end">5</span>
          </li>
        </ul>
        <div class="accordion mt-4" id="accordionOne">
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in payment_factors"
            :key="index"
            :id="'heading' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse'"
                :data-bs-target="'#collapse' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#headingOne"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion mt-4" id="accordionTwo">
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in oneday_factors"
            :key="index"
            :id="'heading-two' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse-two'"
                :data-bs-target="'#collapse-two' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse-two' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse-two' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading-two' + index"
              data-bs-parent="#accordionTwo"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion mt-4" id="accordionThree">
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in taxes_factors"
            :key="index"
            :id="'heading-three' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse-three'"
                :data-bs-target="'#collapse-three' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse-three' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse-three' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading-three' + index"
              data-bs-parent="#accordionThree"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion mt-4" id="accordionFour">
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in finances_factors"
            :key="index"
            :id="'heading-four' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse-four'"
                :data-bs-target="'#collapse-four' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse-four' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse-four' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading-four' + index"
              data-bs-parent="#accordionFour"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Analyze",
  setup() {
    const isOpen = ref(null);
    const payment_factors = ref([
      {
        title: "Банкротсво",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Блокировка счетов",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Обеспечительные меры на имущество",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Дело о банкротстве",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Активные договорные споры в качестве ответчика",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Активные арбитражные дела в качестве третьего лица",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Завершенные арбитражные дела в качестве ответчика",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Активные исполнительные производства",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Завершенные проверки",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Активные проверки",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const oneday_factors = ref([
      {
        title: "Возраст",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Наличие выплат персоналу",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Уставный капитал",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Юридический адрес",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Руководитель",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Учредители",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Недостоверность руководителя",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Недостоверность управляющего органа",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Недостоверность учредителя",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Массовый учредитель",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Массовый руководитель",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Дисквалифицированные лица",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Госзакупки в качестве поставщика",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Недобросовестный поставщик",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Действующие лицензии",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Виды деятельности",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Действующие филиалы и/или представительства",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const taxes_factors = ref([
      {
        title: "Налоговая нагрузка",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Бухгалтерская отчетность",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Налоговая задолженность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const finances_factors = ref([
      {
        title: "Финансовая устойчивость",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Платежеспособность",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Эффективность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }
    return {
      isOpen,
      payment_factors,
      oneday_factors,
      taxes_factors,
      finances_factors,
      toggleCollapse,
    };
  },
});
</script>

<style scoped>
.collapse {
  transition: height 0.35s ease;
}
</style>
