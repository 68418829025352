import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_CompasSection = _resolveComponent("CompasSection")!
  const _component_FeaturesSection = _resolveComponent("FeaturesSection")!
  const _component_InformationSection = _resolveComponent("InformationSection")!
  const _component_AboutSection = _resolveComponent("AboutSection")!
  const _component_PricingSection = _resolveComponent("PricingSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroSection),
    _createVNode(_component_CompasSection),
    _createVNode(_component_FeaturesSection),
    _createVNode(_component_InformationSection),
    _createVNode(_component_AboutSection),
    _createVNode(_component_PricingSection)
  ], 64))
}