
import { defineComponent } from "vue";
import { opportunities } from "@/models/main-page";

export default defineComponent({
  name: "FeaturesSection",
  setup() {
    return {
      opportunities,
    };
  },
});
