<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card finances">
      <div class="card-header">
        <h5 class="card-title mb-0">Арбитражные дела</h5>
      </div>

      <div class="d-flex navs">
        <ul class="nav nav-tabs">
          <li>
            <a
              class="active"
              id="analyze-tab"
              data-bs-toggle="tab"
              href="#tab-analyze"
              role="tab"
              aria-controls="tab-analyze"
              aria-selected="true"
              >Статистика</a
            >
          </li>
          <li class="ms-4">
            <a
              id="deals-tab"
              data-bs-toggle="tab"
              href="#tab-deals"
              role="tab"
              aria-controls="tab-deals"
              aria-selected="false"
              >Дела</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <!-- Первый таб -->
          <div
            class="tab-pane fade show active"
            id="tab-analyze"
            role="tabpanel"
            aria-labelledby="analyze-tab"
          >
            <ArbitrationStatistics />
          </div>

          <!-- Второй таб -->
          <div
            class="tab-pane fade"
            id="tab-deals"
            role="tabpanel"
            aria-labelledby="deals-tab"
          >
            <ArbitrationDeals :cases="cases || []" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import ArbitrationDeals from "./ArbitrationDeals.vue";
import ArbitrationStatistics from "./ArbitrationStatistics.vue";

export default defineComponent({
  name: "Arbitration",
  components: {
    ArbitrationDeals,
    ArbitrationStatistics,
  },
  props: {
    cases: Array,
  },
  setup(props) {
    const finances_factors = ref([
      {
        title: "Финансовая устойчивость",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Зависимость от кредитов",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Обеспеченность собственными средствами",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Зависимость от дебиторов",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Доля заемного капитала",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const payment_factors = ref([
      {
        title: "Абсолютная ликвидность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Текущая ликвидность",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Обеспеченность активами",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Платежеспособность по текущим обязательствам",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Вероятность утраты платежеспособности",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);

    const effectivity_factors = ref([
      {
        title: "Вероятность утраты платежеспособности",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Эффективность вложений",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Отдача от использования активов",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Рентабельность собственного капитала",
        status: "danger",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Оборачиваемость дебиторской задолженности :",
        status: "decline",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      {
        title: "Оборачиваемость кредиторской задолженности",
        status: "success",
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
    ]);
    // Данные для графика
    const chartData = {
      labels: [
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      datasets: [
        {
          label: "Region A",
          data: [10, 20, 15, 25, 30, 35, 40, 35, 20, 15, 38, 22, 35],
          backgroundColor: "#86C2FF4D",
          borderColor: "#86C2FF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
        {
          label: "Region B",
          data: [12, 18, 20, 22, 28, 32, 38, 10, 20, 15, 25, 32, 35],
          backgroundColor: "#B17BFF4D",
          borderColor: "#B17BFF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
      ],
    };

    // Опции для графика
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.parsed.y;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Sales",
          },
        },
        y: {
          display: true, // Скрывает ось X
          grid: {
            display: true, // Скрывает сетку по оси X
          },
          title: {
            display: false, // Скрывает заголовок оси X
          },
          ticks: {
            display: false, // Скрывает заголовок оси X
          },
        },
        r: {
          ticks: {
            callback: function (value) {
              return value.toLocaleString("en-US", {
                minimumFractionDigits: Math.min(Math.max(0, 100), 20),
              });
            },
          },
        },
      },
    };
    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    return {
      finances_factors,
      payment_factors,
      effectivity_factors,
      toggleCollapse,
      chartData,
      chartOptions,
    };
  },
});
</script>
