import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import setup from "./services/setup";

import "@/assets/styles/main.scss";
import "@/assets/styles/fonts.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.css";

setup();

createApp(App).use(store).use(router).mount("#app");
